<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-cadastro">
        Avaliação do Risco
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-epi" v-if="bind && bind.objTipoDocumento.intId != 279">
        Equipamentos de Proteção
        <i :class="$utilidade.getIcone('epi') + ' fa-2x'"></i>
      </v-tab>
      <v-tab
        href="#tab-enquadramento"
        v-if="bind && bind.objTipoDocumento.intId != 279"
      >
        Enquadramento Legal
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <div class="row" v-if="strTab == 'tab-cadastro'">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span
          class="form-title"
          style="margin: 0 !important; padding: 0 !important"
          >Informações Gerais</span
        >

        <div class="row ma-0 pa-0">
          <ed-input-auto-complete
            v-if="!formData.intId && !formData.boolInserirNovoItem"
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pa-0 ma-0"
            v-model="formData.intRiscoId"
            label="Selecionar um risco existente"
            name="intRiscoId"
            route="Cadastro/Risco"
            :disabled="formData.boolInserirNovoItem || formData.intId > 0"
            @selected="setItem"
            :filters="{
              strOrderBy: 'strNome',
            }"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pa-0 ma-0"
            v-model="formData.arrayRevisaoSetorId"
            name="arrayRevisaoSetorId"
            label="Em qual Setor esse risco será incluído ou atualizado?"
            rules="required"
            route="Documento/RevisaoSetor"
            :items="formDataRevisao.setores"
            :disabled="!formData.intRiscoId && !formData.boolInserirNovoItem"
            multiple
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pa-0 ma-0"
            v-model="formData.strNome"
            name="strNome"
            label="Nome"
            required
            rules="required"
            :disabled="
              (!formData.intRiscoId && !formData.boolInserirNovoItem) ||
              formData.intId > 0
            "
          />

          <ed-input-select
            class="
              col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6
              pa-0
              ma-0
              pr-1
            "
            v-model="formData.intParametroFrequenciaId"
            label="Frequência"
            name="intParametroFrequenciaId"
            :items="bind.arrayParametroFrequencia"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pa-0 ma-0"
            v-model="formData.intParametroClassicicacaoEfeitoId"
            label="Classificação"
            name="intParametroClassicicacaoEfeitoId"
            :items="bind.arrayParametroClassificacao"
          />

          <ed-input-money
            v-if="bind && bind.objTipoDocumento.intId != 279"
            class="
              col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6
              pa-0
              ma-0
              pr-1
            "
            v-model="formData.floatLimiteTolerancia"
            label="Limite de Tolerância"
            name="floatLimiteTolerancia"
          />

          <ed-input-select
            v-if="bind && bind.objTipoDocumento.intId != 279"
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pa-0 ma-0"
            v-model="formData.intParametroUnidadeLimiteToleranciaId"
            label="Unidade"
            name="intParametroUnidadeLimiteToleranciaId"
            :items="bind.arrayParametroUnidadeMedida"
          />

          <ed-input-money
            v-if="bind && bind.objTipoDocumento.intId != 279"
            class="
              col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6
              pa-0
              ma-0
              pr-1
            "
            v-model="formData.floatNivelAcao"
            label="Nível de Ação"
            name="floatNivelAcao"
          />

          <ed-input-select
            v-if="bind && bind.objTipoDocumento.intId != 279"
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pa-0 ma-0"
            v-model="formData.intParametroUnidadeNivelAcaoId"
            label="Unidade"
            name="intParametroUnidadeNivelAcaoId"
            :items="bind.arrayParametroUnidadeMedida"
          />

          <ed-input-time
            v-if="bind && bind.objTipoDocumento.intId != 279"
            class="
              col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6
              pa-0
              ma-0
              pr-1
            "
            v-model="formData.strTempoExposicao"
            label="Tempo de Exposição"
            name="strTempoExposicao"
          />

          <span
            v-if="bind && bind.objTipoDocumento.intId != 279"
            class="form-title"
            style="margin: 0 !important; padding: 0 !important"
            >OUTRAS INFORMAÇÕES</span
          >

          <div
            class="row ma-0 pa-0"
            v-if="bind && bind.objTipoDocumento.intId != 279"
          >
            <ed-input-text-area
              class="
                col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                pa-0
                ma-0
              "
              v-model="formData.strFonteGeradora"
              label="Fontes Geradoras"
              name="strFonteGeradora"
            />

            <ed-input-text-area
              class="
                col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                pa-0
                ma-0
              "
              v-model="formData.strDanosSaude"
              label="Possíveis Danos a Saúde"
              name="strDanosSaude"
            />

            <ed-input-text-area
              class="
                col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                pa-0
                ma-0
              "
              v-model="formData.strMedidaControleExistente"
              label="Medidas e Controle Existentes"
              name="strMedidaControleExistente"
            />

            <ed-input-text-area
              class="
                col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                pa-0
                ma-0
              "
              v-model="formData.strMedidaControleProposta"
              label="Medidas e Controle Propostas"
              name="strMedidaControleProposta"
            />

            <ed-input-text-area
              class="
                col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                pa-0
                ma-0
              "
              v-model="formData.strFormaAcompanhamento"
              label="Formas de Acompanhamento"
              name="strFormaAcompanhamento"
            />

            <ed-input-text-area
              class="
                col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                pa-0
                ma-0
              "
              v-model="formData.strDescricaoAgenteNocivo"
              name="strDescricaoAgenteNocivo"
              label="Descrição do Agente Nocivo"
            />

            <ed-input-text-area
              class="
                col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                pa-0
                ma-0
              "
              v-model="formData.strObservacao"
              label="Observações"
              name="strObservacao"
            />
          </div>
        </div>
      </div>

      <div
        class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left"
        v-if="bind && bind.objTipoDocumento.intId != 279"
      >
        <span
          class="form-title"
          style="margin: 0 !important; padding: 0 !important"
          >CLASSIFICAÇÃO OCUPACIONAL DO RISCO
          <i
            :class="$utilidade.getIcone('info')"
            title="Esse indicador define a classificação do risco ANTES implementar as Medidas de Controle Propostas. "
          />
        </span>

        <div class="row ma-0 pa-0">
          <ed-input-select
            class="
              col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4
              pa-0
              ma-0
              pr-1
            "
            v-model="formData.intParametroProbabilidadeOcupacionalId"
            label="Probabilidade"
            name="intParametroProbabilidadeOcupacionalId"
            :items="bind.arrayParametroMatrizProbabilidade"
            @selected="(x) => (formData.probabilidade_ocupacional = x)"
          />

          <ed-input-select
            class="
              col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4
              pa-0
              ma-0
              pr-1
            "
            v-model="formData.intParametroSeveridadeOcupacionalId"
            label="Severidade"
            name="intParametroSeveridadeOcupacionalId"
            :items="bind.arrayParametroMatrizSeveridade"
            @selected="(x) => (formData.severidade_ocupacional = x)"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pa-0 ma-0"
            v-model="formData.intParametroMatrizOcupacionalId"
            label="Classificação Severidade"
            name="intParametroMatrizOcupacionalId"
            :items="bind.arrayParametroMatrizClassificacao"
            :background-color="
              formData.matriz_ocupacional
                ? formData.matriz_ocupacional.strValor.strCor
                : ''
            "
          />
        </div>

        <span
          class="form-title"
          style="margin: 0 !important; padding: 0 !important"
        >
          CLASSIFICAÇÃO RESIDUAL DO RISCO
          <i
            :class="$utilidade.getIcone('info')"
            title="Esse indicador define a classificação do risco APÓS implementar as Medidas de Controle Propostas. "
          />
        </span>

        <div class="row ma-0 pa-0">
          <ed-input-select
            class="
              col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4
              pa-0
              ma-0
              pr-1
            "
            v-model="formData.intParametroProbabilidadeResidualId"
            label="Probabilidade"
            name="intParametroProbabilidadeResidualId"
            :items="bind.arrayParametroMatrizProbabilidade"
            @selected="(x) => (formData.probabilidade_residual = x)"
          />

          <ed-input-select
            class="
              col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4
              pa-0
              ma-0
              pr-1
            "
            v-model="formData.intParametroSeveridadeResidualId"
            label="Severidade"
            name="intParametroSeveridadeResidualId"
            :items="bind.arrayParametroMatrizSeveridade"
            @selected="(x) => (formData.severidade_residual = x)"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pa-0 ma-0"
            v-model="formData.intParametroMatrizResidualId"
            label="Classificação da Severidade"
            name="intParametroMatrizResidualId"
            :items="bind.arrayParametroMatrizClassificacao"
            :background-color="
              formData.matriz_residual
                ? formData.matriz_residual.strValor.strCor
                : ''
            "
          />
        </div>

        <span
          class="form-title"
          style="margin: 0 !important; padding: 0 !important"
          >MEDIÇÃO</span
        >

        <div class="row ma-0 pa-0">
          <ed-input-date
            class="
              col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6
              pa-0
              ma-0
              pr-1
            "
            v-model="formData.strDataMedicao"
            label="Data da Medição"
            name="strDataMedicao"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pa-0 ma-0"
            v-model="formData.strResponsavelMedicao"
            label="Responsável pela Medição"
            name="strResponsavelMedicao"
          />

          <ed-input-money
            class="
              col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6
              pa-0
              ma-0
              pr-1
            "
            v-model="formData.floatMedicao"
            label="Medição"
            name="floatMedicao"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pa-0 ma-0"
            v-model="formData.intParametroUnidadeMedicaoId"
            label="Unidade"
            name="intParametroUnidadeMedicaoId"
            :items="bind.arrayParametroUnidadeMedida"
          />

          <ed-input-money
            class="
              col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6
              pa-0
              ma-0
              pr-1
            "
            v-model="formData.floatMedicaoAtenuada"
            label="Medição Atenuada"
            name="floatMedicaoAtenuada"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pa-0 ma-0"
            v-model="formData.intParametroUnidadeMedicaoAtenuadaId"
            label="Unidade"
            name="intParametroUnidadeMedicaoAtenuadaId"
            :items="bind.arrayParametroUnidadeMedida"
          />

          <ed-input-text
            class="
              col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6
              pa-0
              ma-0
              pr-1
            "
            v-model="formData.strMetodoMedicao"
            label="Método"
            name="strMetodoMedicao"
          />

          <ed-input-tipo
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pa-0 ma-0"
            v-model="formData.intTipoAvaliacaoId"
            label="Tipo de Avaliação"
            name="intTipoAvaliacaoId"
            tipo="1343"
          />
        </div>

        <campos-personalizado
          :formData="formData"
          :key="'campo-' + $root.$session.versao"
          :intTipoModeloId="1338"
          @salvar="(x) => (formData.arrayCamposPersonalizado = x)"
        />
      </div>
    </div>

    <div class="row" v-if="strTab == 'tab-enquadramento'">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <div class="row ma-0 pa-0">
          <span
            class="form-title"
            style="margin: 0 !important; padding: 0 !important"
            >INSALUBRIDADE</span
          >

          <div class="row ma-0 pa-0">
            <ed-input-select
              class="
                col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3
                pa-0
                ma-0
                pr-1
              "
              v-model="formData.boolInsalubridade"
              label="Insalubridade"
              name="boolInsalubridade"
              :items="[
                { intId: 1, strNome: 'SIM' },
                { intId: 0, strNome: 'NÃO' },
              ]"
            />

            <ed-input-money
              class="
                col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3
                pa-0
                ma-0
                pr-1
              "
              v-model="formData.floatValorInsalubridade"
              label="% Insalubridade"
              name="floatValorInsalubridade"
              :disabled="!formData.boolInsalubridade"
            />

            <ed-input-auto-complete
              class="
                col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6
                pa-0
                ma-0
                pr-1
              "
              v-model="formData.intParametroInsalubridadeId"
              label="Anexo NR 15"
              route="Common/Parametro"
              :disabled="!formData.boolInsalubridade"
              name="intTipoInsalubridade"
              :itemList="bind.arrayParametroNr15"
              disabledSync
            />

            <ed-input-text-area
              class="
                col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                pa-0
                ma-0
              "
              v-model="formData.strConclusaoTecnicaInsalubridade"
              label="Conclusão Técnica"
              name="strConclusaoTecnicaInsalubridade"
            />
          </div>

          <span
            class="form-title"
            style="margin: 0 !important; padding: 0 !important"
            >PERICULOSIDADE
          </span>

          <div class="row ma-0 pa-0">
            <ed-input-select
              class="
                col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3
                pa-0
                ma-0
                pr-1
              "
              v-model="formData.boolPericulosidade"
              label="Periculosidade"
              name="boolPericulosidade"
              :items="[
                { intId: 1, strNome: 'SIM' },
                { intId: 0, strNome: 'NÃO' },
              ]"
            />

            <ed-input-money
              class="
                col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3
                pa-0
                ma-0
                pr-1
              "
              v-model="formData.floatValorPericulosidade"
              label="% Periculusidade"
              name="floatValorPericulosidade"
              :disabled="!formData.boolPericulosidade"
            />

            <ed-input-auto-complete
              class="
                col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6
                pa-0
                ma-0
                pr-1
              "
              v-model="formData.intParametroPericulosidadeId"
              route="Common/Parametro"
              label="Anexo NR 16"
              name="intTipoPericulosidade"
              :disabled="!formData.boolPericulosidade"
              :itemList="bind.arrayParametroNr16"
              disabledSync
            />

            <ed-input-text-area
              class="
                col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                pa-0
                ma-0
              "
              v-model="formData.strConclusaoTecnicaPericulosidade"
              label="Conclusão Técnica"
              name="strConclusaoTecnicaPericulosidade"
            />
          </div>

          <span
            class="form-title"
            style="margin: 0 !important; padding: 0 !important"
            >APOSENTADORIA ESPECIAL
          </span>

          <div class="row ma-0 pa-0">
            <ed-input-select
              class="
                col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4
                pa-0
                ma-0
                pr-1
              "
              v-model="formData.boolAposentadoriaEspecial"
              label="Aposentadoria Especial"
              name="boolAposentadoriaEspecial"
              :items="[
                { intId: 1, strNome: 'SIM' },
                { intId: 0, strNome: 'NÃO' },
              ]"
            />

            <ed-input-text
              class="
                col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8
                pa-0
                ma-0
                pr-1
              "
              v-model="formData.intCodigoAposentadoriaEspecialGfip"
              label="Código GFIP"
              name="intCodigoAposentadoriaEspecialGfip"
              type="number"
              :disabled="!formData.boolAposentadoriaEspecial"
            />

            <ed-input-text-area
              class="
                col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                pa-0
                ma-0
              "
              v-model="formData.strConclusaoTecnicaAposentadoriaEspecial"
              label="Conclusão Técnica"
              name="strConclusaoTecnicaAposentadoriaEspecial"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="strTab == 'tab-epi'">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span
          class="form-title"
          style="margin: 0 !important; padding: 0 !important"
          >EPI</span
        >

        <div class="row ma-0 pa-0">
          <ed-input-select
            class="
              col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6
              pa-0
              ma-0
              pr-1
            "
            v-model="formData.intTipoEpiId"
            label="Utiliza EPI"
            name="intTipoEpiId"
            :items="bind.arrayTipoEpiEpc"
          />

          <ed-input-select
            class="
              col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6
              pa-0
              ma-0
              pr-1
            "
            v-model="formData.boolEpiEficaz"
            label="EPI Eficaz"
            name="boolEpiEficaz"
            :rules="formData.intTipoEpiId == 1337 ? 'required' : ''"
            :disabled="!formData.intTipoEpiId || formData.intTipoEpiId != 1337"
            :items="[
              { intId: 1, strNome: 'SIM' },
              { intId: 0, strNome: 'NÃO' },
            ]"
          />
        </div>

        <div class="row ma-0 pa-0 ma-0" v-if="formData.intTipoEpiId == 1337">
          <div class="col-12 pa-0 ma-0">
            <risco-equipamento
              :formData="formData"
              :key="'epi'"
              :intTipoEquipamentoId="1341"
            />
          </div>
        </div>

        <span
          class="form-title"
          style="margin: 0 !important; padding: 0 !important"
          >EPC</span
        >

        <div class="row ma-0 pa-0">
          <ed-input-select
            class="
              col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6
              pa-0
              ma-0
              pr-1
            "
            v-model="formData.intTipoEpcId"
            label="Utiliza EPC"
            name="intTipoEpcId"
            :items="bind.arrayTipoEpiEpc"
          />

          <ed-input-select
            class="
              col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6
              pa-0
              ma-0
              pr-1
            "
            v-model="formData.boolEpcEficaz"
            label="EPC Eficaz"
            name="boolEpcEficaz"
            :rules="formData.intTipoEpcId == 1337 ? 'required' : ''"
            :disabled="!formData.intTipoEpcId || formData.intTipoEpcId != 1337"
            :items="[
              { intId: 1, strNome: 'SIM' },
              { intId: 0, strNome: 'NÃO' },
            ]"
          />
        </div>

        <div class="row ma-0 pa-0 ma-0" v-if="formData.intTipoEpcId == 1337">
          <div class="col-12 pa-0 ma-0">
            <risco-equipamento
              :formData="formData"
              :key="'epc'"
              :intTipoEquipamentoId="1342"
            />
          </div>
        </div>

        <campos-personalizado
          :formData="formData"
          :key="'campo-' + $root.$session.versao"
          :intTipoModeloId="1338"
          @salvar="(x) => (formData.arrayCamposPersonalizado = x)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputTipo,
  EdInputSwitch,
  EdInputMoney,
  EdInputTime,
  EdInputDate,
  EdInputAutoComplete,
  EdInputCheckbox,
} from "@/components/common/form";
import CamposPersonalizado from "@/components/cadastro/formularioPersonalizado/formatar";
import RiscoEquipamento from "./setores-risco-editar-equipamento.vue";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
    formDataRevisao: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdInputText,
    EdInputTextArea,
    EdInputSelect,
    EdInputTipo,
    EdInputSwitch,
    EdInputTime,
    EdInputDate,
    EdInputAutoComplete,
    EdInputCheckbox,
    EdInputMoney,
    CamposPersonalizado,
    RiscoEquipamento,
  },
  inject: ["bind"],
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      strTab: "tab-cadastro",
      objSetor: null,
    };
  },
  computed: {},
  watch: {
    "formData.probabilidade_ocupacional": {
      handler: function (newVal) {
        this.setMatriz();
      },
      deep: true,
    },
    "formData.severidade_ocupacional": {
      handler: function (newVal) {
        this.setMatriz();
      },
      deep: true,
    },
    "formData.probabilidade_residual": {
      handler: function (newVal) {
        this.setMatriz();
      },
      deep: true,
    },
    "formData.severidade_residual": {
      handler: function (newVal) {
        this.setMatriz();
      },
      deep: true,
    },
  },
  methods: {
    initialize() {
      // if(!this.formData.arrayRevisaoSetorId){
      //   this.formData.arrayRevisaoSetorId = []
      // }
      // if(this.formData.intRevisaoSetorId){
      //   this.formData.arrayRevisaoSetorId.push(this.formData.intRevisaoSetorId)
      // }
      // this.$forceUpdate()
    },

    setMatriz() {
      // this.formData.intParametroMatrizOcupacionalId = null;
      // this.formData.intParametroMatrizResidualId = null;

      if (
        this.formData.probabilidade_ocupacional &&
        this.formData.severidade_ocupacional
      ) {
        let objMatriz = this.getMatriz(
          this.formData.probabilidade_ocupacional.intId,
          this.formData.severidade_ocupacional.intId
        );

        this.formData.intParametroMatrizOcupacionalId = objMatriz.intId;
        this.formData.matriz_ocupacional = _.cloneDeep(objMatriz);
      }

      if (
        this.formData.probabilidade_residual &&
        this.formData.severidade_residual
      ) {
        let objMatriz = this.getMatriz(
          this.formData.probabilidade_residual.intId,
          this.formData.severidade_residual.intId
        );

        this.formData.intParametroMatrizResidualId = objMatriz.intId;
        this.formData.matriz_residual = _.cloneDeep(objMatriz);
      }
    },

    getMatriz(intParametroProbabilidadeId, intParametroSeveridadeId) {
      let intParametroMatrizId =
        this.bind.objParametroMatriz.strValor[intParametroProbabilidadeId][
          intParametroSeveridadeId
        ];

      let objTipoMatriz = this.bind.arrayParametroMatrizClassificacao.find(
        (c) => c.intId == intParametroMatrizId
      );

      return objTipoMatriz;
    },

    setItem(objValor) {
      this.formData.strNome = objValor.strNome;
      this.formData.strCbo = objValor.strCbo;
      this.formData.strDescricao = objValor.strDescricao;
    },

    getUltimoSetor() {
      let objUltimoSetor = null;

      if (this.formDataRevisao.setores.length) {
        objUltimoSetor =
          this.formDataRevisao.setores[this.formDataRevisao.setores.length - 1];
      }

      return objUltimoSetor;
    },
  },
};
</script>
