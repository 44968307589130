<template>
  <div>
    <ed-simple-table
      :config="{ registerName: 'Inserir novo Exame' }"
      disabledRegisterRows
      disabledSearchRows
    >
      <thead>
        <tr>
          <th class="text-left">Exame</th>
          <th class="text-left" width="100">Consulta</th>
          <th class="text-left"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(objRevisaoExame, index) in formData.exames" :key="index">
          <td>
            <ed-collapse :textLimit="20" :textValue="objRevisaoExame.exame.strNome" />
          </td>
          <td align="left">
            <span
              v-for="(
                objRevisaoExameConsulta, j
              ) in objRevisaoExame.revisao_setor_exame_consulta"
              :key="j"
            >
              <v-chip
                color="green"
                class="mr-1 mt-1 text-left"
                outlined
                x-small
                v-if="objRevisaoExameConsulta.boolHabilitar"
              >
                {{ objRevisaoExameConsulta.tipo_exame.strNome }}

                <label class="ml-1" v-if="objRevisaoExameConsulta.intMeses">
                  {{ objRevisaoExameConsulta.intMeses }} meses
                </label>

                <label class="ml-1" v-if="objRevisaoExameConsulta.intIdadeMin">
                  a partir de {{ objRevisaoExameConsulta.intIdadeMin }} anos
                </label>

                <label class="ml-1" v-if="objRevisaoExameConsulta.intIdadeMax">
                  até {{ objRevisaoExameConsulta.intIdadeMax }} anos
                </label>

                <label class="ml-1" v-if="objRevisaoExameConsulta.intDiasValidade">
                  válido por {{ objRevisaoExameConsulta.intDiasValidade }} dias
                </label>
              </v-chip>
            </span>
          </td>
          <td class="text-right">
            <ed-button
              v-if="!boolDisableControls"
              :iconLeft="$utilidade.getIcone('editar') + ' fa-3x'"
              color="primary"
              title="Editar Setor"
              icon
              onlyIcon
              @click="!boolDisableControls ? onEditarExame(objRevisaoExame) : null"
            />

            <ed-button
              v-if="!boolDisableControls"
              :iconLeft="$utilidade.getIcone('deletar') + ' fa-3x'"
              color="error"
              title="Deletar Setor"
              icon
              onlyIcon
              @click="!boolDisableControls ? onDelete(objRevisaoExame) : null"
            />
          </td>
        </tr>
        <tr v-if="!formData.exames.length">
          <td colspan="3">Nenhum exame encontrado</td>
        </tr>
      </tbody>
    </ed-simple-table>
  </div>
</template>

<script>
import { EdSimpleTable, EdCollapse, EdButton } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
    boolDisableControls: {
      type: [Boolean],
      required: false,
      default: false,
    },
  },
  components: {
    EdSimpleTable,
    EdCollapse,
    EdButton,
  },
  inject: ["onEditarExame", "getDetalheRevisao"],
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      boolExibirFormulario: false,
    };
  },
  computed: {},
  watch: {
    formData: {
      handler: function (newVal) {},
      deep: true,
    },
  },
  methods: {
    initialize() {},

    onDelete(objSetor) {
      this.$root.$dialog
        .confirm(
          "Tem certeza que deseja excluir o exame " + objSetor.strNome + " desse setor?"
        )
        .then((boolExcluir) => {
          this.$root.$dialog.loading(true);

          this.$root.$request
            .delete("Documento/RevisaoSetorExame", {
              arrayId: [objSetor.intId],
            })
            .then((objResponse) => {
              this.$root.$dialog.loading(false);

              if (objResponse.status == 200) {
                this.getDetalheRevisao();
              }
            });
        });
    },
  },
};
</script>
