<template>
  <div>
    <div class="row">
      <ed-input-auto-complete
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        v-model="formData.intExameId"
        label="Selecionar um exame existente"
        name="intExameId"
        route="Cadastro/Exame"
        :disabled="formData.boolInserirNovoItem || formData.intId > 0"
        :filters="{
          strOrderBy: 'strNome',
        }"
        @selected="setItem"
      />

      <ed-input-select
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        v-model="formData.arrayRevisaoSetorId"
        name="arrayRevisaoSetorId"
        label="Em qual Setor esse exame será incluído ou atualizado?"
        rules="required"
        route="Documento/RevisaoSetor"
        :items="formDataRevisao.setores"
        :disabled="!formData.intExameId && !formData.boolInserirNovoItem"
        multiple
      />

      <ed-input-text
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        v-model="formData.strNome"
        name="strNome"
        label="Nome"
        required
        rules="required"
        :disabled="
          (!formData.intExameId && !formData.boolInserirNovoItem) || formData.intId > 0
        "
      />

      <div
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-lef"
        v-if="formData.intExameId && formData.arrayRevisaoSetorId.length"
      >
        <div class="row pa-0 mt-0">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
            <span class="form-title">Que tipo de consulta esse exame será incluído?</span>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
            <ed-simple-table disabledRegisterRows disabledSearchRows>
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Meses</th>
                  <th>Validade do Exame (em dias)</th>
                  <th>Idade Mínima</th>
                  <th>Idade Máxima</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(
                    objRevisaoSetorExameConsulta, index
                  ) in formData.revisao_setor_exame_consulta"
                  :key="index"
                >
                  <td>
                    <ed-input-switch
                      v-model="objRevisaoSetorExameConsulta.boolHabilitar"
                      name="boolHabilitar"
                      inline
                      :label="objRevisaoSetorExameConsulta.tipo_exame.strNome"
                    />
                  </td>
                  <td align="left">
                    <ed-input-select
                      class="mt-2 mb-2"
                      v-if="
                        objRevisaoSetorExameConsulta.boolHabilitar &&
                        [
                          'consultaSubGrupoAsoPeriodico',
                          'consultaSubGrupoAsoAposAdmissional',
                        ].indexOf(objRevisaoSetorExameConsulta.tipo_exame.strChave) != -1
                      "
                      v-model="objRevisaoSetorExameConsulta.intMeses"
                      name="intMeses"
                      rules="required"
                      itemValue="intId"
                      :items="objRevisaoSetorExameConsulta.tipo_exame.strValor.meses"
                      :hideDetails="true"
                    />
                    <ed-input-select
                      class="mt-2 mb-2"
                      v-if="
                        objRevisaoSetorExameConsulta.boolHabilitar &&
                        ['consultaSubGrupoAsoPeriodico'].indexOf(
                          objRevisaoSetorExameConsulta.tipo_exame.strChave
                        ) != -1 &&
                        Number(objRevisaoSetorExameConsulta.intIdadeMin)
                      "
                      v-model="objRevisaoSetorExameConsulta.intMesesAntesIdadeMin"
                      name="intMesesAntesIdadeMin"
                      itemValue="strValor"
                      :items="objRevisaoSetorExameConsulta.tipo_exame.strValor.meses"
                      :label="
                        'Antes dos ' + objRevisaoSetorExameConsulta.intIdadeMin + ' anos'
                      "
                    />

                    <ed-input-select
                      class="mt-2 mb-2"
                      v-if="
                        objRevisaoSetorExameConsulta.boolHabilitar &&
                        ['consultaSubGrupoAsoPeriodico'].indexOf(
                          objRevisaoSetorExameConsulta.tipo_exame.strChave
                        ) != -1 &&
                        Number(objRevisaoSetorExameConsulta.intIdadeMax)
                      "
                      v-model="objRevisaoSetorExameConsulta.intMesesDepoisIdadeMax"
                      name="intMesesDepoisIdadeMax"
                      itemValue="strValor"
                      :items="objRevisaoSetorExameConsulta.tipo_exame.strValor.meses"
                      :label="
                        'Depois dos ' + objRevisaoSetorExameConsulta.intIdadeMax + ' anos'
                      "
                    />

                    <span
                      v-if="
                        [
                          'consultaSubGrupoAsoPeriodico',
                          'consultaSubGrupoAsoAposAdmissional',
                        ].indexOf(objRevisaoSetorExameConsulta.tipo_exame.strChave) == -1
                      "
                      >---</span
                    >
                  </td>
                  <td valign="middle">
                    <ed-input-text
                      class="mt-2 mb-2"
                      v-if="objRevisaoSetorExameConsulta.boolHabilitar"
                      v-model="objRevisaoSetorExameConsulta.intDiasValidade"
                      name="intDiasValidade"
                      type="number"
                      hideDetails
                    />

                    <span v-if="!objRevisaoSetorExameConsulta.boolHabilitar">---</span>
                  </td>
                  <td valign="middle">
                    <ed-input-text
                      class="mt-2 mb-2"
                      v-if="objRevisaoSetorExameConsulta.boolHabilitar"
                      v-model="objRevisaoSetorExameConsulta.intIdadeMin"
                      name="intIdadeMin"
                      type="number"
                      hideDetails
                    />

                    <span v-if="!objRevisaoSetorExameConsulta.boolHabilitar">---</span>
                  </td>
                  <td>
                    <ed-input-text
                      class="mt-2 mb-2"
                      v-if="objRevisaoSetorExameConsulta.boolHabilitar"
                      v-model="objRevisaoSetorExameConsulta.intIdadeMax"
                      name="intIdadeMax"
                      type="number"
                      hideDetails
                    />
                    <span v-if="!objRevisaoSetorExameConsulta.boolHabilitar">---</span>
                  </td>
                </tr>
              </tbody>
            </ed-simple-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputSwitch,
  EdInputAutoComplete,
  EdSimpleTable,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
    formDataRevisao: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdInputText,
    EdInputTextArea,
    EdInputSelect,
    EdInputSwitch,
    EdInputAutoComplete,
    EdSimpleTable,
  },
  inject: ["bind"],
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      objSetor: null,
    };
  },
  computed: {},
  watch: {
    formData: {
      handler: function (newVal) {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    initialize() {
      if (
        !this.formData.revisao_setor_exame_consulta ||
        !this.formData.revisao_setor_exame_consulta.length
      ) {
        this.formData.revisao_setor_exame_consulta = [];
        this.bind.arrayParametroConsulta.forEach((objTipoConsulta) => {
          this.formData.revisao_setor_exame_consulta.push({
            intId: 0,
            intRevisaoSetorExameId: this.formData.intId,
            intParametroExameId: objTipoConsulta.intId,
            tipo_exame: objTipoConsulta,
            boolHabilitar: 0,
            intIdadeMin: null,
            intIdadeMax: null,
            intMeses: 12,
            intMesesAntesIdadeMin: null,
            intMesesDepoisIdadeMax: null,
          });
        });
      }

      // this.formData.revisao_setor_exame_consulta.forEach(
      //   (objRevisaoSetorExameConsulta) => {
      //     objRevisaoSetorExameConsulta.boolHabilitar = Number(
      //       objRevisaoSetorExameConsulta.boolHabilitar
      //     );

      //     if (objRevisaoSetorExameConsulta.intMeses) {
      //       objRevisaoSetorExameConsulta.intMeses =
      //         Number(objRevisaoSetorExameConsulta.intMeses);
      //     }

      //     if (objRevisaoSetorExameConsulta.intMesesAntesIdadeMin) {
      //       objRevisaoSetorExameConsulta.intMesesAntesIdadeMin =
      //         objRevisaoSetorExameConsulta.intMesesAntesIdadeMin.toString();
      //     }

      //     if (objRevisaoSetorExameConsulta.intMesesAntesIdadeMax) {
      //       objRevisaoSetorExameConsulta.intMesesAntesIdadeMax =
      //         objRevisaoSetorExameConsulta.intMesesAntesIdadeMax.toString();
      //     }
      //   }
      // );

      console.log("sdfasdf", this.formData.revisao_setor_exame_consulta);

      this.$forceUpdate();
    },
    setItem(objValor) {
      this.formData.strNome = objValor.strNome;
      this.formData.strCbo = objValor.strCbo;
      this.formData.strDescricao = objValor.strDescricao;
    },

    getLabelMeses(objRevisaoSetorExameConsulta) {
      if (
        objRevisaoSetorExameConsulta.tipo_exame.strChave != "consultaSubGrupoAsoPeriodico"
      )
        return false;

      if (
        Number(objRevisaoSetorExameConsulta.intIdadeMin) &&
        !Number(objRevisaoSetorExameConsulta.intIdadeMax)
      ) {
        return "A partir de " + objRevisaoSetorExameConsulta.intIdadeMin + " anos";
      } else if (
        !Number(objRevisaoSetorExameConsulta.intIdadeMin) &&
        Number(objRevisaoSetorExameConsulta.intIdadeMax)
      ) {
        return "Antes de " + objRevisaoSetorExameConsulta.intIdadeMax + " anos";
      } else if (
        Number(objRevisaoSetorExameConsulta.intIdadeMin) &&
        Number(objRevisaoSetorExameConsulta.intIdadeMax)
      ) {
        return (
          "Entre " +
          objRevisaoSetorExameConsulta.intIdadeMin +
          " e " +
          objRevisaoSetorExameConsulta.intIdadeMax +
          " anos"
        );
      }

      return false;
    },

    getUltimoSetor() {
      let objUltimoSetor = null;

      if (this.formDataRevisao.setores.length) {
        objUltimoSetor = this.formDataRevisao.setores[
          this.formDataRevisao.setores.length - 1
        ];
      }

      return objUltimoSetor;
    },
  },
};
</script>
