<template>
  <div class="pa-0 mb-5">
    <!-- <ed-modal v-model="bind.boolExibirCadastro" width="50%">
      <div slot="title">
        {{
          formDataCadastro.intId
            ? "Editar Equipamento"
            : "Inserir Novo Equipamento"
        }}
      </div>

      <div class="" slot="content">
        <ed-form
          :formData="formDataCadastro"
          :handlerSave="salvar"
          disableFixedSave
        >
          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-0"
            v-model="formDataCadastro.intEquipamentoFabricanteId"
            label="Equipamento"
            placeholder="Pesquisar pelo nome ou CA"
            name="intEquipamentoFabricanteId"
            itemText="strNumeroCa"
            route="Cadastro/EquipamentoFabricante"
            :filters="{
              intTipoEquipamentoId: this.intTipoEquipamentoId,
            }"
            @selected="setEquipamento"
          />
        </ed-form>
      </div>
    </ed-modal> -->

    <div class="row">
      <ed-input-auto-complete
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        v-model="formDataCadastro.intEquipamentoFabricanteId"
        label="Inserir Novo Equipamento"
        placeholder="Pesquisar pelo nome ou CA"
        name="intEquipamentoFabricanteId"
        itemText="strNumeroCa"
        route="Cadastro/EquipamentoFabricante"
        :filters="{
          intTipoEquipamentoId: this.intTipoEquipamentoId,
        }"
        @selected="setEquipamento"
      >
        <ed-button
          slot="addonRight"
          title="Adicionar"
          @click="salvar"
          :iconLeft="$utilidade.getIcone('cadastrar')"
          :disabled="!formDataCadastro.intEquipamentoFabricanteId"
          small
          onlyIcon
        />
      </ed-input-auto-complete>
    </div>

    <ed-simple-table
      :config="{ registerName: 'Inserir novo equipamento' }"
      disabledSearchRows
      disabledRegisterRows
    >
      <thead>
        <tr>
          <th width="100">CA</th>
          <th>Equipamento</th>
          <th>Dt. Validade</th>
          <th>Eficaz</th>
          <th width="50"></th>
        </tr>
      </thead>
      <tbody>
        <template v-if="formData.equipamentos && formData.equipamentos.length">
          <template
            v-for="(objRevisaoRiscoEquipamento, index) in formData.equipamentos"
          >
            <tr
              :key="index"
              v-if="
                objRevisaoRiscoEquipamento.intTipoEquipamentoId ==
                intTipoEquipamentoId
              "
            >
              <td>
                {{
                  objRevisaoRiscoEquipamento.equipamento_fabricante.strNumeroCa
                }}
              </td>
              <td>
                {{
                  objRevisaoRiscoEquipamento.equipamento_fabricante.equipamento
                    .strNome
                }}
              </td>
              <td>
                {{
                  objRevisaoRiscoEquipamento.equipamento_fabricante
                    .strDataValidade
                    ? $utilidade.toDate(
                        objRevisaoRiscoEquipamento.equipamento_fabricante
                          .strDataValidade,
                        true
                      )
                    : "---"
                }}
              </td>
              <td>
                <ed-input-checkbox
                  v-model="objRevisaoRiscoEquipamento.boolEficaz"
                  class="pa-0 ma-0"
                  name="boolEficaz"
                  inline
                />
              </td>
              <td>
                <ed-button
                  :iconLeft="$utilidade.getIcone('deletar')"
                  color="error"
                  title="Deletar"
                  icon
                  onlyIcon
                  @click="onDelete(index)"
                />
              </td>
            </tr>
          </template>
        </template>
        <tr v-if="!formData.equipamentos || !formData.equipamentos.length">
          <td colspan="4" align="center">Nenhum equipamento cadastrado</td>
        </tr>
      </tbody>
    </ed-simple-table>
  </div>
</template>

<script>
import {
  EdSimpleTable,
  EdForm,
  EdModal,
  EdButton,
  EdInputAutoComplete,
  EdInputText,
  EdInputDate,
  EdInputCheckbox,
} from "@/components/common/form";
export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
    intTipoEquipamentoId: {
      type: [Number, String],
      required: true,
      default: null,
    },
  },
  components: {
    EdForm,
    EdSimpleTable,
    EdInputAutoComplete,
    EdInputText,
    EdInputDate,
    EdModal,
    EdButton,
    EdInputCheckbox,
  },
  inject: [],
  provide() {
    return {};
  },
  mounted() {},
  created() {},
  data() {
    return {
      bind: {
        boolExibirCadastro: false,
      },
      formDataCadastro: {
        intId: 0,
        intTipoEquipamentoId: this.intTipoEquipamentoId,
        intRiscoId: this.formData.intId,
        intEquipamentoFabricanteId: null,
        fabricante: null,
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    initialize() {
      this.formDataCadastro = {
        intId: 0,
        intTipoEquipamentoId: this.intTipoEquipamentoId,
        intRiscoId: this.formData.intId,
        intEquipamentoFabricanteId: null,
        fabricante: null,
      };
    },

    onDelete(index) {
      this.formData.equipamentos.splice(index, 1);
      this.$forceUpdate();
    },

    setEquipamento(obj) {
      if (obj) {

        this.formDataCadastro.equipamento_fabricante = obj;
        this.formDataCadastro.intTipoEquipamentoId = obj.equipamento.intTipoEquipamentoId;
        this.formDataCadastro.boolEficaz = true;
      }
    },

    salvar() {
      if (!this.formData.equipamentos) {
        this.formData.equipamentos = [];
      }

      let self = this;
      let index = this.formData.equipamentos.findIndex(
        (c) =>
          c.intTipoEquipamentoId == self.intTipoEquipamentoId &&
          c.equipamento_fabricante.strNumeroCa ==
            this.formDataCadastro.equipamento_fabricante.strNumeroCa
      );

      if (index == -1) {
        this.formData.equipamentos.push(_.cloneDeep(this.formDataCadastro));
      }

      this.initialize();
      this.$forceUpdate();
    },
  },
};
</script>
