<template>
  <div>
    <ed-simple-table
      :config="{ registerName: 'Inserir nova função' }"
      disabledRegisterRows
      disabledSearchRows
    >
      <thead>
        <tr>
          <th class="text-left">Risco</th>
          <th class="text-left" v-if="bind && bind.objTipoDocumento && bind.objTipoDocumento.intId != 279">
            Meio de Prograpação
          </th>
          <th
            class="text-left"
            width="170"
            v-if="bind && bind.objTipoDocumento && bind.objTipoDocumento && bind.objTipoDocumento.intId != 279"
          >
            Class. Ocupacional
          </th>
          <th
            class="text-left"
            width="170"
            v-if="bind && bind.objTipoDocumento && bind.objTipoDocumento.intId != 279"
          >
            Class. Residual
          </th>
          <th class="text-left" width="105"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(objRevisaoRisco, index) in formData.riscos" :key="index">
          <td class="text-left">
            <ed-collapse :textLimit="100" :textValue="objRevisaoRisco.risco.strNome" />
            <span class="ed-table-info">
              <label>
                <b>CATEGORIA:</b>
                {{
                  objRevisaoRisco.risco.categoria
                    ? objRevisaoRisco.risco.categoria.strNome
                    : "---"
                }}
              </label>
            </span>
          </td>
          <td v-if="bind.objTipoDocumento && bind.objTipoDocumento.intId != 279">
            <span
              v-for="(objMeioPropagacao, indexMeioPropagacao) in objRevisaoRisco.risco
                .meio_propagacao"
              :key="indexMeioPropagacao"
              style="display: block; width: 100%"
            >
              {{ objMeioPropagacao.meio_propagacao.strNome }}
            </span>
          </td>
          <td v-if="bind.objTipoDocumento && bind.objTipoDocumento.intId != 279">
            <span
              v-if="objRevisaoRisco.matriz_ocupacional"
              :style="'background:' + objRevisaoRisco.matriz_ocupacional.strValor.strCor"
            >
              {{ objRevisaoRisco.matriz_ocupacional.strNome }}
              <span
                style="width: 100%; display: block; font-size: 8pt"
                v-if="
                  objRevisaoRisco.probabilidade_ocupacional &&
                  objRevisaoRisco.severidade_ocupacional
                "
              >
                P{{ objRevisaoRisco.probabilidade_ocupacional.strCodigo }} x S{{
                  objRevisaoRisco.severidade_ocupacional.strCodigo
                }}
              </span>
            </span>
          </td>
          <td v-if="bind.objTipoDocumento && bind.objTipoDocumento.intId != 279">
            <span
              v-if="objRevisaoRisco.matriz_residual"
              :style="'background:' + objRevisaoRisco.matriz_residual.strValor.strCor"
            >
              {{ objRevisaoRisco.matriz_residual.strNome }}

              <span
                style="width: 100%; display: block; font-size: 8pt"
                v-if="
                  objRevisaoRisco.probabilidade_residual &&
                  objRevisaoRisco.severidade_residual
                "
              >
                P{{ objRevisaoRisco.probabilidade_residual.strCodigo }} x S{{
                  objRevisaoRisco.severidade_residual.strCodigo
                }}
              </span>
            </span>
          </td>
          <td class="text-right">
            <ed-button
              v-if="!boolDisableControls"
              :iconLeft="$utilidade.getIcone('editar') + ' fa-3x'"
              color="primary"
              title="Editar Setor"
              icon
              onlyIcon
              @click="!boolDisableControls ? onEditarRisco(objRevisaoRisco) : null"
            />

            <ed-button
              v-if="!boolDisableControls"
              :iconLeft="$utilidade.getIcone('deletar') + ' fa-3x'"
              color="error"
              title="Deletar Setor"
              icon
              onlyIcon
              @click="!boolDisableControls ? onDelete(objRevisaoRisco) : null"
            />
          </td>
        </tr>
        <tr v-if="!formData.riscos.length">
          <td colspan="3">Nenhum risco encontrado</td>
        </tr>
      </tbody>
    </ed-simple-table>
  </div>
</template>

<script>
import { EdSimpleTable, EdCollapse, EdButton } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
    boolDisableControls: {
      type: [Boolean],
      required: false,
      default: false,
    },
  },
  components: {
    EdSimpleTable,
    EdCollapse,
    EdButton,
  },
  inject: ["onEditarRisco", "getDetalheRevisao", "bind"],
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      boolExibirFormulario: false,
    };
  },
  computed: {},
  watch: {
    formData: {
      handler: function (newVal) {},
      deep: true,
    },
  },
  methods: {
    initialize() {},

    onDelete(objSetor) {
      this.$root.$dialog
        .confirm(
          "Tem certeza que deseja excluir o risco " + objSetor.strNome + " desse setor?"
        )
        .then((boolExcluir) => {
          this.$root.$dialog.loading(true);

          this.$root.$request
            .delete("Documento/RevisaoSetorRisco", {
              arrayId: [objSetor.intId],
            })
            .then((objResponse) => {
              this.$root.$dialog.loading(false);

              if (objResponse.status == 200) {
                this.getDetalheRevisao();
              }
            });
        });
    },
  },
};
</script>
