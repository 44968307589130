<template>
  <div class="row ma-0 pa-0">
    <template
      v-if="
        boolMostrarRevisao &&
        !boolPesquisandoRevisao &&
        formData.intId &&
        formDataRevisaoDocumento
      "
    >
      <v-tabs v-model="strTab" centered icons-and-text>
        <v-tab
          :href="'#PCMSO'"
          @click="formatarRevisao('PCMSO')"
          v-if="
            $root.$session.verificarPermissaoModulo(9) &&
            $root.$session.verificarPermissaoMenu('pcmso')
          "
        >
          PCMSO
          <i :class="$utilidade.getIcone('pcmso') + ' fa-2x'"></i>
        </v-tab>

        <v-tab
          :href="'#PGR'"
          @click="formatarRevisao('PGR')"
          v-if="
            $root.$session.verificarPermissaoModulo(10) &&
            $root.$session.verificarPermissaoMenu('gro')
          "
        >
          PGR
          <i :class="$utilidade.getIcone('pgr') + ' fa-2x'"></i>
        </v-tab>

        <v-tab
          :href="'#LTCAT'"
          @click="formatarRevisao('LTCAT')"
          v-if="
            $root.$session.verificarPermissaoModulo(10) &&
            $root.$session.verificarPermissaoMenu('gro')
          "
        >
          LTCAT
          <i :class="$utilidade.getIcone('ltcat') + ' fa-2x'"></i>
        </v-tab>
      </v-tabs>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <span class="form-title">Revisões do {{ strTab }}</span>
      </div>

      <template>
        <div>
          <ed-button
            color="primary"
            :iconLeft="$utilidade.getIcone('cadastrar')"
            :label="'Inserir Nova Revisão'"
            class="mr-1"
            @click="inserirRevisao"
          />
        </div>

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formDataRevisaoDocumento.intId"
          name="intId"
          label="Selecione uma Revisão"
          :items="arrayRevisao"
          rules="required"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"
          v-model="formDataRevisaoDocumento.strNumero"
          name="strNumero"
          label="Número da Revisão"
          rules="required"
        />

        <ed-input-date
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formDataRevisaoDocumento.strDataDocumento"
          name="strDataDocumento"
          label="Data do Documento"
          rules="required"
        />

        <ed-input-tipo
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formDataRevisaoDocumento.intTipoStatusId"
          name="intTipoStatusId"
          label="Status"
          tipo="288"
          rules="required"
        />

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formDataRevisaoDocumento.intProfissionalId"
          label="Profissional Responsável"
          name="intProfissionalId"
          route="Cadastro/Profissional"
          :items="bind.arrayProfissional"
        />

        <div class="col-12 text-center">
          <ed-button
            color="primary"
            :iconLeft="$utilidade.getIcone('salvar')"
            :label="'Salvar ' + formDataRevisaoDocumento.tipo_documento.strNome"
            class="mr-1"
            @click="salvar"
          />

          <ed-button
            color="warning"
            :iconLeft="$utilidade.getIcone('download')"
            :label="'Gerar ' + formDataRevisaoDocumento.tipo_documento.strNome"
            class="mr-1"
            @click="gerarDocumento(formDataRevisaoDocumento.intId)"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import {
  EdButton,
  EdSimpleTable,
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputTipo,
  EdInputCheckbox,
  EdInputSwitch,
  EdInputTime,
  EdInputDate,
  EdInputMoney,
  EdInputAutoComplete,
  EdModal,
  EdForm,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
    formDataInserir: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdButton,
    EdInputText,
    EdSimpleTable,
    EdInputSelect,
    EdInputTipo,
    EdInputTextArea,
    EdInputTime,
    EdInputCheckbox,
    EdInputSwitch,
    EdInputAutoComplete,
    EdInputDate,
    EdInputMoney,
    EdModal,
    EdForm,
  },
  inject: [
    "bind",
    "inserirRevisao",
    "intTipoDocumentoId",
    "gerarDocumento",
    "boolMostrarRevisao",
    "boolExisteRevisao",
    "boolPesquisandoRevisao",
  ],
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      strTab: "",
      strTabEmissao: "tab-revisao-0",
      arrayRevisao: [],
      boolExibirCronogramaAcao: false,
      formDataCronograma: null,
      formDataRevisaoDocumento: null,
    };
  },
  computed: {},
  watch: {
    formData: {
      handler: function (newVal) {},
      deep: true,
    },
  },
  methods: {
    initialize() {
      this.formData.documentos.forEach((element) => {
        if (
          !this.strTab &&
          this.intTipoDocumentoId == element.intTipoDocumentoId
        ) {
          this.strTab = element.tipo_documento.strNome;
        }
      });

      this.formatarRevisao(this.strTab);
    },

    formatarRevisao(strTab) {
      this.arrayRevisao = [];

      this.formData.documentos.forEach((element) => {
        if (strTab == element.tipo_documento.strNome) {
          element.strNome =
            "Revisao " +
            element.strNumero +
            " ( em " +
            this.$utilidade.toDate(element.strDataDocumento, true) +
            ")";

          this.formDataRevisaoDocumento = Object.assign(element, {});
          this.arrayRevisao.push(element);
        }
      });

      this.$emit("revisaoDocumento", this.formDataRevisaoDocumento);
    },

    cronogramaAcao(objRevisaoDocumento) {
      this.formDataCronograma = Object.assign(objRevisaoDocumento, {});
      this.boolExibirCronogramaAcao = !this.boolExibirCronogramaAcao;
    },

    salvar() {
      this.$root.$dialog.loading(true);

      return this.$root.$request
        .post("Documento/RevisaoDocumento", this.formDataRevisaoDocumento)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
          } 
        });
    },
  },
};
</script>
