<template>
  <div>
    <ed-modal v-model="boolExibirFormulario" width="50%">
      <div slot="title">
        {{ formDataEditar.intId ? "Editar nova Ação" : "Inserir nova Ação" }}
      </div>

      <div class="" slot="content">
        <ed-form
          :formData="formDataEditar"
          :handlerSave="salvarCronograma"
          disableFixedSave
        >
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
            <div class="row">
              <span class="form-title" style="margin: 0 !important; padding: 0 !important"
                >Informação da Ação</span
              >

              <ed-input-text
                class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-0"
                v-model="formDataEditar.strAcao"
                name="strAcao"
                label="Nome da Ação"
                rules="required"
              />

              <ed-input-text
                class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-0"
                v-model="formDataEditar.strMeta"
                name="strMeta"
                label="Meta"
              />

              <ed-input-text
                class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-0"
                v-model="formDataEditar.strResponsavel"
                name="strResponsavel"
                label="Responsável"
              />

              <ed-input-select
                class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0"
                v-model="formDataEditar.intParametroStatusId"
                name="intParametroStatusId"
                label="Status"
                rules="required"
                :items="bind.arrayCronogramaStatus"
                @selected="setStatus"
              />

              <ed-input-select
                class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0"
                v-model="formDataEditar.intParametroPrioridadeId"
                name="intParametroPrioridadeId"
                label="Prioridade"
                rules="required"
                :items="bind.arrayCronogramaPrioridade"
                @selected="setPrioridade"
              />

              <span class="form-title" style="margin: 0 !important; padding: 0 !important"
                >Planejado</span
              >

              <ed-input-date
                class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0 pr-1"
                v-model="formDataEditar.strDataInicioPlanejado"
                name="strDataInicioPlanejado"
                label="Data de Início"
                rules="required"
              />

              <ed-input-date
                class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0"
                v-model="formDataEditar.strDataTerminoPlanejado"
                name="strDataTerminoPlanejado"
                label="Data de Término"
              />

              <span class="form-title" style="margin: 0 !important; padding: 0 !important"
                >Realizado</span
              >

              <ed-input-date
                class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0 pr-1"
                v-model="formDataEditar.strDataInicioRealizado"
                name="strDataInicioRealizado"
                label="Data de Início"
              />

              <ed-input-date
                class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0 mb-4"
                v-model="formDataEditar.strDataTerminoRealizado"
                name="strDataTerminoRealizado"
                label="Data de Término"
              />
            </div>
          </div>
        </ed-form>
      </div>
    </ed-modal>

    <ed-simple-table
      v-if="boolMostrarRevisao && !boolPesquisandoRevisao"
      :config="{ registerName: 'Inserir nova Ação' }"
      @register="onRegister"
      disabledSearchRows
    >
      <thead>
        <tr>
          <th class="text-left">Ação</th>
          <th class="text-left" width="80">Prioridade</th>
          <th class="text-left" width="150">Status</th>
          <th class="text-left" width="200">Planejado</th>
          <th class="text-left" width="200">Realizado</th>
          <th class="text-left">Responsável</th>
          <th class="text-left"></th>
        </tr>
      </thead>
      <tbody v-if="formData.cronogramas">
        <tr v-for="(objCronograma, index) in formData.cronogramas" :key="index">
          <td>
            {{ objCronograma.strAcao }}
          </td>
          <td align="left">
            {{ objCronograma.prioridade ? objCronograma.prioridade.strNome : "---" }}
          </td>
          <td align="left">
            {{ objCronograma.status ? objCronograma.status.strNome : "---" }}
          </td>
          <td align="left">
            {{
              $utilidade.toDate(
                $utilidade.convertDate(
                  objCronograma.strDataInicioPlanejado,
                  "YYYY-MM-DD"
                ),
                true
              )
            }}
            <span v-if="objCronograma.strDataTerminoPlanejado">
              -
              {{
                $utilidade.toDate(
                  $utilidade.convertDate(
                    objCronograma.strDataTerminoPlanejado,
                    "YYYY-MM-DD"
                  ),
                  true
                )
              }}
            </span>
          </td>
          <td align="left">
            <span v-if="objCronograma.strDataInicioRealizado">
              {{
                $utilidade.toDate(
                  $utilidade.convertDate(
                    objCronograma.strDataInicioRealizado,
                    "YYYY-MM-DD"
                  ),
                  true
                )
              }}
            </span>

            <span v-if="objCronograma.strDataTerminoRealizado">
              -
              {{
                $utilidade.toDate(
                  $utilidade.convertDate(
                    objCronograma.strDataTerminoRealizado,
                    "YYYY-MM-DD"
                  ),
                  true
                )
              }}
            </span>
          </td>
          <td align="left">
            {{ objCronograma.strResponsavel ? objCronograma.strResponsavel : "---" }}
          </td>
          <td class="text-right">
            <ed-button
              :iconLeft="$utilidade.getIcone('editar') + ' fa-3x'"
              color="primary"
              title="Editar Setor"
              icon
              onlyIcon
              @click="onEdit(objCronograma)"
            />

            <ed-button
              :iconLeft="$utilidade.getIcone('deletar') + ' fa-3x'"
              color="error"
              title="Deletar Setor"
              icon
              onlyIcon
              @click="onDelete(index)"
            />
          </td>
        </tr>
        <tr v-if="!formData.cronogramas.length">
          <td colspan="7" align="center">Nenhum cronograma encontrado</td>
        </tr>
      </tbody>
    </ed-simple-table>
  </div>
</template>

<script>
import {
  EdSimpleTable,
  EdModal,
  EdForm,
  EdButton,
  EdInputText,
  EdInputDate,
  EdInputSelect,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
    boolDisableControls: {
      type: [Boolean],
      required: false,
      default: false,
    },
  },
  components: {
    EdSimpleTable,
    EdModal,
    EdForm,
    EdButton,
    EdInputText,
    EdInputDate,
    EdInputSelect,
  },
  inject: [
    "salvar",
    "bind",
    "boolMostrarRevisao",
    "boolExisteRevisao",
    "boolPesquisandoRevisao",
  ],
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      boolExibirFormulario: false,
      formDataEditar: {},
    };
  },
  computed: {},
  watch: {
    formData: {
      handler: function (newVal) {},
      deep: true,
    },
  },
  methods: {
    initialize() {},
    onRegister() {
      this.boolExibirFormulario = true;
      this.formDataEditar = {
        intId: 0,
        strAcao: null,
        strMeta: null,
        intParametroStatusId: this.bind.cronogramaStatusAFazer.intId,
        intParametroPrioridadeId: this.bind.cronogramaPrioridadeAlta.intId,
        prioridade: this.bind.cronogramaPrioridadeAlta,
        status: this.bind.cronogramaStatusAFazer,
        strDataInicioPlanejado: this.$utilidade.toDate(null, false, "YYYY-MM-DD"),
        strDataTerminoPlanejado: null,
        strDataInicioRealizado: null,
        strDataTerminoRealizado: null,
        strResponsavel: null,
      };
    },
    onEdit(data) {
      this.formDataEditar = _.cloneDeep(data);
      this.boolExibirFormulario = true;
    },
    onDelete(index) {
      this.formData.cronogramas.splice(index, 1);
    },

    setPrioridade(obj) {
      if (obj) {
        this.formDataEditar.prioridade = obj;
      }
    },
    setStatus(obj) {
      if (obj) {
        this.formDataEditar.status = obj;
      }
    },

    salvarCronograma() {
      if (this.formDataEditar.intId > 0) {
        let index = this.formData.cronogramas.findIndex(
          (c) => c.intId == this.formDataEditar.intId
        );
        this.formData.cronogramas[index] = this.formDataEditar;
      } else {
        this.formData.cronogramas.push(this.formDataEditar);
      }

      this.salvar()
        .then((result) => {
          this.boolExibirFormulario = false;
          this.$emit('salvarCronograma')
        })
        .catch((erro) => {
          if (this.formDataEditar.intId <= 0) {
            this.onDelete(this.formData.cronogramas.length - 1);
          }
        });
    },
  },
};
</script>
