<template>
  <div class="row ma-0 pa-0 text-left" v-if="formData && formData.setores">
    <ed-modal v-model="boolExibirFormulario" width="90%">
      <div slot="title">{{ strTituloFormularioEdicao }}</div>

      <div class="" slot="content">
        <ed-form
          :formData="formData"
          :handlerSave="salvarFormularioEdicao"
          disableFixedSave
        >
          <editar-setor
            v-if="strTipoFormularioEdicao == 'RevisaoSetor'"
            :formData="formDataEditar"
            :formDataRevisao="formData"
          />
          <editar-funcao
            v-if="strTipoFormularioEdicao == 'RevisaoSetorFuncao'"
            :formData="formDataEditar"
            :formDataRevisao="formData"
          />
          <editar-risco
            v-if="strTipoFormularioEdicao == 'RevisaoSetorRisco'"
            :formData="formDataEditar"
            :formDataRevisao="formData"
            @initialize="$emit('initialize', $event)"
          />
          <editar-exame
            v-if="strTipoFormularioEdicao == 'RevisaoSetorExame'"
            :formData="formDataEditar"
            :formDataRevisao="formData"
          />
        </ed-form>
      </div>
    </ed-modal>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-0">
      <ed-button
        label="Inserir novo Setor"
        color="primary"
        class="mr-1"
        :iconLeft="this.$utilidade.getIcone('setores')"
        @click="onEditarSetor()"
      />
      <ed-button
        v-if="formData.setores.length"
        label="Inserir Função"
        color="primary"
        class="mr-1"
        :iconLeft="this.$utilidade.getIcone('funcao')"
        @click="onEditarFuncao()"
      />

      <ed-button
        v-if="formData.setores.length"
        label="Inserir Risco"
        color="primary"
        class="mr-1"
        :iconLeft="this.$utilidade.getIcone('risco')"
        @click="onEditarRisco()"
      />

      <ed-button
        v-if="formData.setores.length && bind.objTipoDocumento.intId == 279"
        label="Inserir Exame"
        color="primary"
        class="mr-1"
        :iconLeft="this.$utilidade.getIcone('exame')"
        @click="onEditarExame()"
      />
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <v-card
        class="mb-5"
        color="#eeeeee"
        light
        v-for="(objRevisaoSetor, index) in formData.setores"
        hover
        :key="index"
      >
        <v-card-title @click="ocultarExibirSetor(objRevisaoSetor)" class="ed-mouse-hand">

          <div class="col-9 ma-0 pa-0 text-left">
            <span>{{'GHE ' + objRevisaoSetor.intOrdem + ' (' + objRevisaoSetor.strNome + ')'}}</span>

          <ed-button
            :iconLeft="$utilidade.getIcone('editar') + ' fa-3x'"
            color="success"
            title="Editar Setor"
            icon
            onlyIcon
            @click="onEditarSetor(objRevisaoSetor)"
          />

          <ed-button
            :iconLeft="$utilidade.getIcone('deletar')"
            color="error"
            title="Deletar Setor"
            icon
            onlyIcon
            @click="onDeleteSetor(objRevisaoSetor)"
          />
          </div>
          <div class="col-3 ma-0 pa-0 text-right">
            <i
              :class="
                !objRevisaoSetor.boolExibirSetor
                  ? $utilidade.getIcone('setaBaixo')
                  : $utilidade.getIcone('setaCima')
              "
            ></i>
          </div>
        </v-card-title>

        <v-card-text class="white text--primary text-left">
          <div class="col-12 text-center" v-show="!objRevisaoSetor.boolExibirSetor">
            <ed-button
              :iconLeft="
                (!objRevisaoSetor.boolExibirSetor
                  ? $utilidade.getIcone('setaBaixo')
                  : $utilidade.getIcone('setaCima')) + ' fa-3x'
              "
              label="Clique para exibir as informações do setor"
              text
              @click="ocultarExibirSetor(objRevisaoSetor)"
            />
          </div>
          <div class="col-12" v-show="objRevisaoSetor.boolExibirSetor">
            <div class="row ma-0 pa-0">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
                <span class="ed-label">Descrição do setor</span><br />
                <ed-collapse
                  v-if="objRevisaoSetor.strDescricaoAtividades"
                  :textLimit="100"
                  :textValue="objRevisaoSetor.strDescricaoAtividades"
                />
                <span v-else>---</span>
              </div>

              <v-tabs v-model="objRevisaoSetor.strTab" centered icons-and-text>
                <v-tab href="#tab-funcao">
                  Funções
                  <i :class="$utilidade.getIcone('funcao') + ' fa-2x'"></i>
                </v-tab>

                <v-tab href="#tab-risco">
                  Riscos
                  <i :class="$utilidade.getIcone('risco') + ' fa-2x'"></i>
                </v-tab>

                <v-tab href="#tab-exame" v-if="bind.objTipoDocumento.intId == 279">
                  Exames
                  <i :class="$utilidade.getIcone('exame') + ' fa-2x'"></i>
                </v-tab>
              </v-tabs>

              <funcao
                :formData="objRevisaoSetor"
                v-if="objRevisaoSetor.strTab == 'tab-funcao'"
                :key="'funcao-' + $root.$session.versao"
              />
              <risco
                :formData="objRevisaoSetor"
                v-if="objRevisaoSetor.strTab == 'tab-risco'"
                :key="'risco-' + $root.$session.versao"
              />
              <exame
                :formData="objRevisaoSetor"
                v-if="objRevisaoSetor.strTab == 'tab-exame'"
                :key="'exame-' + $root.$session.versao"
              />

              <!-- <div
                :class="
                  bind.objTipoDocumento.intId == 279
                    ? 'col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 border mt-3'
                    : 'col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 border mt-3'
                "
              >
                <h4><i :class="$utilidade.getIcone('funcao')"></i> Funções</h4>
                <funcao :formData="objRevisaoSetor" />
              </div> -->

              <!-- <div
                :class="
                  bind.objTipoDocumento.intId == 279
                    ? 'col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 border mt-3'
                    : 'col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 border mt-3'
                "
              >
                <h4><i :class="$utilidade.getIcone('risco')"></i> Riscos</h4>
                <risco :formData="objRevisaoSetor" />
              </div> -->

              <!-- <div
                class="
                  col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4
                  border
                  mt-3
                "
                v-if="bind.objTipoDocumento.intId == 279"
              >
                <h4><i :class="$utilidade.getIcone('exame')"></i> Exames</h4>
                <exame :formData="objRevisaoSetor" />
              </div> -->
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import Funcao from "./setores-funcao.vue";
import Risco from "./setores-risco.vue";
import Exame from "./setores-exame.vue";
import EditarSetor from "./partials/setores-editar.vue";
import EditarFuncao from "./partials/setores-funcao-editar.vue";
import EditarRisco from "./partials/setores-risco-editar.vue";
import EditarExame from "./partials/setores-exame-editar.vue";

import { EdButton, EdForm, EdModal, EdCollapse } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdButton,
    EdModal,
    EdForm,
    EdCollapse,
    Funcao,
    Risco,
    Exame,
    EditarSetor,
    EditarFuncao,
    EditarRisco,
    EditarExame,
  },
  inject: ["bind", "getDetalheRevisao"],
  provide() {
    return {
      onEditarFuncao: this.onEditarFuncao,
      onEditarRisco: this.onEditarRisco,
      onEditarExame: this.onEditarExame,
      salvar: this.salvarFormularioEdicao,
      getDetalheRevisao: this.getDetalheRevisao,
      bind: this.bind,
    };
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      boolExibirFormulario: false,
      strTituloFormularioEdicao: null,
      strTipoFormularioEdicao: null,
      formDataEditar: null,
    };
  },
  computed: {},
  watch: {
    formData: {
      handler: function (newVal) {},
      deep: true,
    },
    boolExibirFormularioFuncao() {},
  },
  methods: {
    initialize() {},

    onDeleteSetor(objSetor) {
      this.$root.$dialog
        .confirm(
          "O setor " +
            objSetor.strNome +
            " será excluído de todos os documentos vinculados (PGR/PCMSO,LTCAT) e o processo não poderá ser desfeito. Deseja continuar?"
        )
        .then((boolExcluir) => {
          this.$root.$dialog.loading(true);

          this.$root.$request
            .delete("Documento/RevisaoSetor", { arrayId: [objSetor.intId] })
            .then((objResponse) => {
              this.$root.$dialog.loading(false);

              if (objResponse.status == 200) {
                this.getDetalheRevisao();
              }
            });
        });
    },

    onEditarSetor(objData) {
      this.boolExibirFormulario = !this.boolExibirFormulario;
      this.strTipoFormularioEdicao = "RevisaoSetor";

      if (objData) {
        this.strTituloFormularioEdicao = "Editar Setor :: " + objData.setor.strNome;
        this.formDataEditar = Object.assign(objData, {});
      } else {
        this.strTituloFormularioEdicao = "Inserir Setor";
        this.formDataEditar = {
          intId: 0,
          boolInserirNovoItem: false,
          boolCopiarSetor: false,
          intRevisaoId: this.formData.intId,
          intCadastroGeralSetorId: null,
          intCadastroGeralCopiaId: null,
          intCadastroGeralUnidadeCopiaId: null,
          intRevisaoCopiaId: null,
          intRevisaoSetorCopiaId: null,
          strNome: null,
          strDescricaoAtividades: null,
          intOrdem: null,
        };
      }
    },

    onEditarFuncao(objData) {
      this.boolExibirFormulario = !this.boolExibirFormulario;
      this.strTipoFormularioEdicao = "RevisaoSetorFuncao";

      if (objData) {
        this.strTituloFormularioEdicao = "Editar Função :: " + objData.funcao.strNome;
        this.formDataEditar = Object.assign(objData, {
          arrayRevisaoSetorId: [objData.intRevisaoSetorId],
        });
      } else {
        this.strTituloFormularioEdicao = "Inserir Função";
        this.formDataEditar = {
          intId: 0,
          boolInserirNovoItem: false,
          intRevisaoId: this.formData.intId,
          intFuncaoId: null,
          strNome: null,
          strDescricao: null,
          intTotalMasculino: 0,
          intTotalFeminino: 0,
          arrayRevisaoSetorId: [],
        };
      }
    },

    onEditarRisco(objData) {
      this.boolExibirFormulario = !this.boolExibirFormulario;
      this.strTipoFormularioEdicao = "RevisaoSetorRisco";

      if (objData) {
        this.strTituloFormularioEdicao = "Editar Risco :: " + objData.risco.strNome;
        this.formDataEditar = Object.assign(objData, {
          arrayRevisaoSetorId: [objData.intRevisaoSetorId],
        });

        this.$root.$dialog.loading(true);
        this.$root.$request
          .get("Documento/RevisaoSetorRisco", { intId: objData.intId })
          .then((objResponse) => {
            this.$root.$dialog.loading(false);

            if (objResponse.status == 200) {
              console.log("aaaaaaaaaaaaa", objResponse);
              this.formDataEditar = Object.assign(objResponse.result, {
                arrayRevisaoSetorId: [objData.intRevisaoSetorId],
              });
            }
          });
      } else {
        this.strTituloFormularioEdicao = "Inserir Risco";
        this.formDataEditar = {
          intId: 0,
          boolInserirNovoItem: false,
          boolInsalubridade: 0,
          boolPericulosidade: 0,
          boolAposentadoriaEspecial: 0,
          intTipoEpiId: 1335,
          intTipoEpcId: 1335,
          boolEpiEficaz: 1,
          boolEpcEficaz: 1,
          intRevisaoId: this.formData.intId,
          intRisco: null,
          strNome: null,
          arrayRevisaoSetorId: [],
        };
      }
    },

    onEditarExame(objData) {
      this.boolExibirFormulario = !this.boolExibirFormulario;
      this.strTipoFormularioEdicao = "RevisaoSetorExame";

      if (objData) {
        this.strTituloFormularioEdicao = "Editar Exame :: " + objData.exame.strNome;
        this.formDataEditar = Object.assign(objData, {
          arrayRevisaoSetorId: [objData.intRevisaoSetorId],
        });
      } else {
        this.strTituloFormularioEdicao = "Inserir Exame";

        this.formDataEditar = {
          intId: 0,
          boolInserirNovoItem: false,
          intRevisaoId: this.formData.intId,
          intexameId: null,
          strNome: null,
          revisao_setor_exame_consulta: [],
          arrayRevisaoSetorId: [],
        };
      }
    },

    ocultarExibirSetor(objSetor) {
      if (objSetor.boolExibirSetor == undefined) {
        objSetor.boolExibirSetor = false;
      }

      objSetor.boolExibirSetor = !objSetor.boolExibirSetor;
      this.$forceUpdate();
    },

    salvarFormularioEdicao() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .post("Documento/" + this.strTipoFormularioEdicao, this.formDataEditar)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            
            if(!this.formDataEditar.intId){
              this.boolExibirFormulario = !this.boolExibirFormulario;
            }
            this.getDetalheRevisao();
          }
        });
    },
  },
};
</script>
