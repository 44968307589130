<template>
  <div v-if="this.bind.objTipoDocumento && bind.boolCarregado">
    <div class="row ma-0 pa-0">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <span class="form-title">Informações da Empresa</span>
      </div>

      <ed-input-auto-complete
        class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
        v-model="formData.intCadastroGeralEmpresaId"
        label="Selecione a Empresa"
        name="intCadastroGeralId"
        route="Cadastro/CadastroGeral"
        :filters="{
          intTipoCadastroGeralId: 58,
        }"
        :initializeItems="false"
        :itemList="bind.objCadastroGeral ? [bind.objCadastroGeral] : []"
      />

      <ed-input-select
        class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
        v-model="formData.intCadastroGeralUnidadeId"
        label="Selecione a Unidade de Trabalho"
        name="intCadastroGeralUnidadeId"
        :items="bind.arrayCadastroGeralUnidade"
        :placeholder="
          !this.formData.intCadastroGeralEmpresaId
            ? 'Selecione a empresa'
            : null
        "
        :disabled="!this.formData.intCadastroGeralEmpresaId"
        :initializeItems="false"
        :itemList="
          bind.objCadastroGeralUnidade ? [bind.objCadastroGeralUnidade] : []
        "
      />
    </div>

    <div class="row ma-0 pa-0 text-left" v-if="boolMostrarRevisao">
      <div
        class="col-12 text-center"
        v-if="
          bind.boolCarregado &&
          boolMostrarRevisao &&
          !bind.boolPesquisandoRevisao &&
          !bind.arrayCadastroGeralUnidadeRevisao.length
        "
      >
        <ed-alert class="text-left" type="warning">
          Não existe nenhum documento cadastrado para essa empresa
        </ed-alert>

        <ed-button
          color="primary"
          label="Inserir nova revisão"
          :iconLeft="this.$utilidade.getIcone('cadastrar')"
          @click="inserirRevisao"
        />
      </div>

      <revisao :formData="formDataRevisao" :formDataInserir="formData" @revisaoDocumento="selecionarRevisaoDocomento" />

      <div
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center mb-5"
        v-if="
          boolMostrarRevisao &&
          boolExisteRevisao &&
          !this.bind.boolPesquisandoRevisao
        "
      >
        <!-- <ed-button
          color="error"
          :iconLeft="$utilidade.getIcone('deletar')"
          label="Excluir revisão"
          @click="deletarRevisao"
        /> -->
      </div>
    </div>

    <div
      class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
      v-if="formDataRevisaoDocumento && formDataRevisaoDocumento.intId"
    >
      <span class="form-title">Cronograma de Ação</span>
      <cronograma
          :formData="formDataRevisaoDocumento"
          :key="'tab-cronograma-' + $root.$session.versao"
          @initialize="initialize()"
        />
    </div>

    <div
      class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
      v-if="formDataRevisao && formDataRevisao.setores"
    >
      <span class="form-title">Informações dos Setores</span>
    </div>
    <setores
      :formData="formDataRevisao"
      :formDataDocumento="formDataRevisaoDocumento"
      :key="'ghe-' + $root.$session.versao"
      @initialize="initialize()"
    />
  </div>
  
</template>

<script>
import Revisao from "./partials/revisao";
import Setores from "./partials/setores";
import Cronograma from "./partials/cronograma";
import {
  EdInputAutoComplete,
  EdInputSelect,
  EdAlert,
  EdButton,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    intCadastroGeralEmpresaId: {
      type: [Number],
      required: true,
      default: null,
    },
    intCadastroGeralUnidadeId: {
      type: [Number],
      required: false,
      default: null,
    },
    intRevisaoId: {
      type: [Number],
      required: false,
      default: null,
    },
    intTipoDocumentoId: {
      type: [Number],
      required: true,
      default: null,
    },
    intTipoRevisaoId: {
      type: [Number],
      required: true,
      default: null,
    },
  },
  components: {
    EdInputAutoComplete,
    EdInputSelect,
    Revisao,
    Setores,
    EdAlert,
    EdButton,
    Cronograma
  },
  provide() {
    return {
      bind: this.bind,
      intTipoDocumentoId: this.intTipoDocumentoId,
      getDetalheRevisao: this.getDetalheRevisao,
      salvar: this.salvar,
      initialize: this.initialize,
      inserirRevisao: this.inserirRevisao,
      boolMostrarRevisao: this.boolMostrarRevisao,
      boolExisteRevisao: this.boolExisteRevisao,
      boolPesquisandoRevisao: this.bind.boolPesquisandoRevisao,
      gerarDocumento: this.gerarDocumento,
    };
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      strTab: "tab-revisao",
      formData: {
        intId: 0,
        intCadastroGeralEmpresaId: this.intCadastroGeralEmpresaId,
        intCadastroGeralUnidadeId: this.intCadastroGeralUnidadeId,
        intTipoDocumentoId: this.intTipoDocumentoId,
        intTipoRevisaoId: this.intTipoRevisaoId,
        intRevisaoId: this.intRevisaoId,
        boolAtivo: 1,
        strDataDocumento: this.$utilidade.toDate(null, true),
        strDataVencimentoDocumento: this.$utilidade.addDate(
          12,
          null,
          "M",
          "DD/MM/YYYY"
        ),
      },
      formDataRevisao: {
        intId: 0,
        documentos: [],
      },
      formDataRevisaoDocumento:{

      },
      bind: {
        boolPesquisandoRevisao: false,
        boolCarregado: false,
        objTipoDocumento: null,
        objTipoRevisao: null,
        objCadastroGeral: null,
        objCadastroGeralUnidade: null,
        cronogramaStatusAFazer: {},
        cronogramaPrioridadeAlta: {},
        arrayCadastroGeralUnidade: [],
        arrayCadastroGeralUnidadeRevisao: [],
        arrayProfissional: [],
        arrayModelo: [],
        arrayParametroConsulta: [],
        arrayParametroFrequencia: [],
        arrayParametroClassificacao: [],
        arrayParametroUnidadeMedida: [],
        arrayParametroMatrizProbabilidade: [],
        arrayParametroMatrizSeveridade: [],
        arrayParametroMatrizClassificacao: [],
        arrayRevisao: [],
        arrayTipoDocumento: [],
        arrayParametroNr15: [],
        arrayParametroNr16: [],
        objParametroMatriz: [],
        arrayTipoEpiEpc: [],
        arrayCronogramaStatus: [],
        arrayCronogramaPrioridade: [],
      },
    };
  },
  watch: {
    "formData.intCadastroGeralEmpresaId"() {
      this.formData.intCadastroGeralUnidadeId = null;
      this.bind.arrayCadastroGeralUnidadeRevisao = [];
      this.formDataRevisao = {
        intId: 0,
        documentos: [],
      };
      this.getCadastroGeralUnidade();
    },
    "formData.intCadastroGeralUnidadeId"() {
      this.bind.arrayCadastroGeralUnidadeRevisao = [];
      this.formDataRevisao = {
        intId: 0,
        documentos: [],
      };
      this.getRevisao();
    },
    "formDataRevisao.intId"() {
      this.getDetalheRevisao();
    },
  },
  computed: {
    boolMostrarRevisao() {
      return (
        this.formData.intCadastroGeralEmpresaId &&
        this.formData.intCadastroGeralUnidadeId &&
        this.intTipoDocumentoId
      );
    },
    boolExisteRevisao() {
      return this.bind.arrayCadastroGeralUnidadeRevisao.length > 0;
    },
  },
  methods: {
    initialize() {
      this.$root.$dialog.loading(true);

      this.formData.documentos = [];

      Promise.all([
        this.$root.$request.get("Documento/Revisao", {
          boolInicializar: true,
          intTipoDocumentoId: this.intTipoDocumentoId,
          intCadastroGeralEmpresaId: this.intCadastroGeralEmpresaId,
          intCadastroGeralUnidadeId: this.intCadastroGeralUnidadeId,
          intTipoRevisaoId: this.intTipoRevisaoId,
          intTipoProfissionalId: this.intTipoDocumentoId == 279 ? 269 : null,
          strOrderBy: "strCodigo",
        }),
      ]).then(([objDados]) => {
        this.$root.$dialog.loading(false);

        if (objDados && objDados.status == 200) {
          this.bind.arrayModelo = objDados.result.arrayModelo.data;
          this.bind.objTipoDocumento = objDados.result.objTipoDocumento;
          this.bind.objTipoRevisao = objDados.result.objTipoRevisao;

          this.bind.objCadastroGeral = objDados.result.objCadastroGeral;
          this.bind.arrayParametroConsulta =
            objDados.result.arrayParametro.consultaSubGrupoAso.parametros;
          this.bind.arrayProfissional = objDados.result.arrayProfissional.data;
          this.bind.objCadastroGeralUnidade =
            objDados.result.objCadastroGeralUnidade;

          this.bind.arrayParametroFrequencia =
            objDados.result.arrayParametro.riscoFrequencia.parametros;

          this.bind.arrayParametroClassificacao =
            objDados.result.arrayParametro.riscoClassificacao.parametros;

          this.bind.arrayParametroUnidadeMedida =
            objDados.result.arrayParametro.riscoUnidade.parametros;

          this.bind.arrayParametroMatrizSeveridade =
            objDados.result.arrayParametro.riscoSeveridade.parametros;
          this.bind.arrayParametroMatrizProbabilidade =
            objDados.result.arrayParametro.riscoProbabilidade.parametros;
          this.bind.arrayParametroMatrizClassificacao =
            objDados.result.arrayParametro.riscoClassificacaoSeveridade.parametros;
          this.bind.objParametroMatriz =
            objDados.result.arrayParametro.riscoMatriz;

          this.bind.arrayParametroNr15 =
            objDados.result.arrayParametro.riscoNr15.parametros;
          this.bind.arrayParametroNr16 =
            objDados.result.arrayParametro.riscoNr16.parametros;

          this.bind.arrayTipoEpiEpc = objDados.result.arrayTipo[1334];

          this.bind.arrayCronogramaStatus =
            objDados.result.arrayParametro.cronogramaStatus.parametros;
          this.bind.arrayCronogramaPrioridade =
            objDados.result.arrayParametro.cronogramaPrioridade.parametros;

          this.bind.cronogramaStatusAFazer =
            objDados.result.arrayParametro.cronogramaStatusAFazer;

          this.bind.cronogramaPrioridadeAlta =
            objDados.result.arrayParametro.cronogramaPrioridadeAlta;

          if (this.bind.objCadastroGeralUnidade) {
            this.bind.arrayCadastroGeralUnidade = [
              this.bind.objCadastroGeralUnidade,
            ];
          }
        }

        this.getRevisao();
      });
    },

    selecionarRevisaoDocomento(obj){
      this.formDataRevisaoDocumento = Object.assign(this.formDataRevisaoDocumento,obj)
    },

    getCadastroGeralUnidade() {
      if (!this.formData.intCadastroGeralEmpresaId) {
        return;
      }

      this.bind.arrayCadastroGeralUnidade = [];

      this.$root.$request
        .get("Cadastro/CadastroGeralUnidade", {
          intCadastroGeralEmpresaId: this.formData.intCadastroGeralEmpresaId,
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);
          this.bind.boolPesquisandoRevisao = false;

          if (objResponse.status == 200) {
            this.bind.arrayCadastroGeralUnidade = objResponse.result.data;
          }
        });
    },

    getRevisao() {
      if (!this.boolMostrarRevisao) {
        this.bind.boolCarregado = true;
        return;
      }

      this.$root.$dialog.loading(true);
      this.bind.boolPesquisandoRevisao = true;
      this.bind.arrayRevisao = [];

      this.$root.$request
        .get("Documento/Revisao", {
          //intTipoDocumentoId: this.intTipoDocumentoId,
          intTipoRevisaoId: this.intTipoRevisaoId,
          intCadastroGeralEmpresaId: this.formData.intCadastroGeralEmpresaId,
          intCadastroGeralUnidadeId: this.formData.intCadastroGeralUnidadeId,
          boolBuscarRevisaoCompleta: true,
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);
          this.bind.boolPesquisandoRevisao = false;

          this.bind.arrayCadastroGeralUnidadeRevisao = [];
          if (objResponse.status == 200) {
            this.bind.arrayCadastroGeralUnidadeRevisao =
              objResponse.result.data;
            if (
              this.bind.arrayCadastroGeralUnidadeRevisao.length &&
              this.bind.arrayCadastroGeralUnidadeRevisao[0].revisoes.length
            ) {
              this.formDataRevisao = Object.assign(
                this.bind.arrayCadastroGeralUnidadeRevisao[0].revisoes[0]
              );
            }
          }

          this.bind.boolCarregado = true;
        });
    },

    getDetalheRevisao() {
      if (!this.formDataRevisao.intId) {
        return;
      }

      this.$root.$dialog.loading(true);

      let arrayTipoDocumento = [this.bind.objTipoDocumento.intId];

      if (this.bind.objTipoDocumento.intId != 279) {
        arrayTipoDocumento.push(281);
      }

      this.$root.$request
        .get("Documento/Revisao", {
          intId: this.formDataRevisao.intId,
          intTipoDocumentoId: arrayTipoDocumento,
          intTipoRevisaoId: this.intTipoRevisaoId,
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            let index = 0;
            objResponse.result.setores.forEach((objRevisaoRestor) => {
              objRevisaoRestor.strTab = "tab-funcao";
              if (index == 0) {
                objRevisaoRestor.boolExibirSetor = true;
              }
              index++;
            });
            this.formDataRevisao = Object.assign(objResponse.result, {});
          }
        });
    },

    deletarRevisao() {
      this.$root.$dialog
        .confirm(
          "Tem certeza que deseja deletar essa revisão do " +
            this.bind.objTipoDocumento.strNome +
            "?"
        )
        .then((boolExcluir) => {
          this.$root.$dialog.loading(true);

          this.$root.$request
            .delete("Documento/Revisao", {
              arrayId: [this.formDataRevisao.intId],
            })
            .then((objResponse) => {
              this.$root.$dialog.loading(false);

              if (objResponse.status == 200) {
                this.$root.$message.success("Dados excluídos com sucesso");
                window.location.reload(true);
              }
            });
        });
    },

    inserirRevisao() {
      this.$root.$dialog
        .confirm("Tem certeza que deseja criar uma nova revisão?")
        .then((boolExcluir) => {
          this.$root.$dialog.loading(true);

          this.$root.$request
            .post("Documento/Revisao", this.formData)
            .then((objResponse) => {
              this.$root.$dialog.loading(false);

              if (objResponse.status == 200) {
                if (this.$route.name.indexOf("edit") == -1) {
                  this.$router.push({
                    name: this.$route.name.replace(".register", ".edit"),
                    params: {
                      intCadastroGeralEmpresaId:
                        objResponse.result.cadastro_geral.intId,
                      intCadastroGeralUnidadeId:
                        objResponse.result.unidade.intId,
                      intRevisaoId: objResponse.result.intId,
                    },
                  });
                } else {
                  window.location.reload(true);
                }
              }
            });
        });
    },

    gerarDocumento(intRevisaoDocumentoId) {
      this.$root.$request.open("Documento/Revisao/Get", {
        boolGerarDocumento: true,
        intId: this.formDataRevisao.intId,
        intRevisaoDocumentoId: intRevisaoDocumentoId,
      });
    },

    salvar() {
      this.$root.$dialog.loading(true);

      return new Promise((resolve, reject) => {
        return this.$root.$request
          .post("Documento/Revisao", this.formDataRevisao)
          .then((objResponse) => {
            this.$root.$dialog.loading(false);

            if (objResponse.status == 200) {
              this.getRevisao();
              resolve();
            } else {
              reject();
            }
          });
      });
    },
  },
};
</script>
