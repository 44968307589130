<template>
  <div>
    <div v-for="(objFormulario, index) in arrayCamposPersonalizado" :key="index">
      <span class="form-title">{{ objFormulario.strNome }}</span>
      <div class="row">
        <template v-for="(objCampoPersonalizado, index) in objFormulario.campos">
          <ed-input-text
            v-if="objCampoPersonalizado.intTipoCampoId == 302"
            :key="index"
            :class="
              objCampoPersonalizado.strClasse
                ? objCampoPersonalizado.strClasse
                : 'col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'
            "
            v-model="objCampoPersonalizado.conteudo[0].strValor"
            :name="'campo-' + objCampoPersonalizado.intId"
            :label="objCampoPersonalizado.strNome"
            :rules="objCampoPersonalizado.boolObrigatorio ? 'required' : ''"
          />

          <ed-input-text-area
            v-else-if="objCampoPersonalizado.intTipoCampoId == 303"
            :key="index"
            :class="
              objCampoPersonalizado.strClasse
                ? objCampoPersonalizado.strClasse
                : 'col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'
            "
            v-model="objCampoPersonalizado.conteudo[0].strValor"
            :name="'campo-' + objCampoPersonalizado.intId"
            :label="objCampoPersonalizado.strNome"
            :rules="objCampoPersonalizado.boolObrigatorio ? 'required' : ''"
          />

          <ed-input-text
            v-else-if="objCampoPersonalizado.intTipoCampoId == 304"
            :key="index"
            type="number"
            :class="
              objCampoPersonalizado.strClasse
                ? objCampoPersonalizado.strClasse
                : 'col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'
            "
            v-model="objCampoPersonalizado.conteudo[0].strValor"
            :name="'campo-' + objCampoPersonalizado.intId"
            :label="objCampoPersonalizado.strNome"
            :rules="objCampoPersonalizado.boolObrigatorio ? 'required' : ''"
          />

          <ed-input-money
            v-else-if="objCampoPersonalizado.intTipoCampoId == 305"
            :key="index"
            :class="
              objCampoPersonalizado.strClasse
                ? objCampoPersonalizado.strClasse
                : 'col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'
            "
            v-model="objCampoPersonalizado.conteudo[0].strValor"
            :name="'campo-' + objCampoPersonalizado.intId"
            :label="objCampoPersonalizado.strNome"
            :rules="objCampoPersonalizado.boolObrigatorio ? 'required' : ''"
            disabledIcon
          />

          <ed-input-select
            v-else-if="objCampoPersonalizado.intTipoCampoId == 306"
            :key="index"
            :class="
              objCampoPersonalizado.strClasse
                ? objCampoPersonalizado.strClasse
                : 'col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'
            "
            v-model="objCampoPersonalizado.conteudo[0].intFormularioCampoOpcaoId"
            :name="'campo-' + objCampoPersonalizado.intId"
            :label="objCampoPersonalizado.strNome"
            :multiple="objCampoPersonalizado.boolMultiplo ? true : false"
            :rules="objCampoPersonalizado.boolObrigatorio ? 'required' : ''"
            :items="objCampoPersonalizado.opcoes"
          />

          <template v-else-if="objCampoPersonalizado.intTipoCampoId == 328">
            <div
              :class="
                objCampoPersonalizado.strClasse
                  ? objCampoPersonalizado.strClasse
                  : 'col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'
              "
              :key="index + '-default'"
            >
              <div class="ed-form-label text-left pt-0" :key="index">
                <span style="color: #c63535" v-if="objCampoPersonalizado.boolObrigatori"
                  >*</span
                >
                {{ objCampoPersonalizado.strNome }}
              </div>

              <template v-for="(objOpcao, indexOpcao) in objCampoPersonalizado.opcoes">
                <ed-input-checkbox
                  class=""
                  style="width: auto; float: left; margin-right: 10px !important"
                  v-model="objCampoPersonalizado.conteudo[indexOpcao].strValor"
                  :labelItem="objOpcao.strNome"
                  :rules="objCampoPersonalizado.boolObrigatorio ? 'required' : ''"
                  :key="index + '-' + indexOpcao"
                />
                <ed-input-text
                  v-if="
                    objOpcao.boolPermitirOutro &&
                    objCampoPersonalizado.conteudo[indexOpcao].strValor
                  "
                  style="float: left"
                  class="mt-3"
                  :key="index + '-outro-' + indexOpcao"
                  type="text"
                  v-model="objCampoPersonalizado.conteudo[indexOpcao].strObservacao"
                  :name="'campo-outro-' + objOpcao.intId"
                  :rules="
                    objCampoPersonalizado.conteudo[indexOpcao].strValor ? 'required' : ''
                  "
                  hideDetails
                />
              </template>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdAlert,
  EdInputText,
  EdInputMoney,
  EdInputTextArea,
  EdInputSelect,
  EdInputTipo,
  EdInputSwitch,
  EdInputCheckbox,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    intTipoModeloId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    formData: {
      type: [Object],
      required: true,
      default: null,
    },
    parametros: {
      type: [Object],
      required: false,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdAlert,
    EdInputText,
    EdInputMoney,
    EdInputTextArea,
    EdInputSelect,
    EdInputTipo,
    EdInputSwitch,
    EdInputCheckbox,
  },
  mounted() {
    this.getFormulario();
  },
  created() {},
  data() {
    return {
      arrayCamposPersonalizado: [],
    };
  },
  watch: {
    intTipoModeloId() {},
    "formData.intId"() {
      this.getFormulario();
    },
    arrayCamposPersonalizado: {
      handler: function (newVal) {
        this.setEvento();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    setEvento() {
      this.$emit("salvar", this.arrayCamposPersonalizado);
    },

    getFormulario() {
      this.$root.$dialog.loading(true);

      let arrayCategoria = [];

      this.$root.$request
        .get("Cadastro/Formulario", {
          parametros: this.parametros,
          intTipoModeloId: this.intTipoModeloId,
          intPointId: this.formData.intId,
          boolMostrarSomenteAtivo: true,
          intRegistroPagina: 200,
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);
          let intIndexCategoria = -1;

          if (objResponse.status == 200 && objResponse.result) {
            objResponse.result.data.forEach((objFormulario) => {
              objFormulario.categorias.forEach((objFormularioCategoria) => {
                arrayCategoria.push(objFormularioCategoria);
              });
            });

            arrayCategoria.forEach((objCategoria) => {
              objCategoria.campos.forEach((objFormularioCampo) => {
                if (!objFormularioCampo.conteudo.length) {
                  let arrayConteudo = [];

                  if (objFormularioCampo.intTipoCampoId != 328) {
                    arrayConteudo = [
                      {
                        intId: 0,
                        strValor: null,
                        intFormularioCampoOpcaoId: null,
                      },
                    ];
                  } else {
                    objFormularioCampo.opcoes.forEach((objOpcao) => {
                      arrayConteudo.push({
                        intId: 0,
                        strValor: null,
                        intFormularioCampoOpcaoId: objOpcao.intId,
                      });
                    });
                  }
                  objFormularioCampo.conteudo = arrayConteudo;
                }
              });
            });
          }
          this.arrayCamposPersonalizado = arrayCategoria;

          this.$utilidade.console("CampoPersonalizado", this.arrayCamposPersonalizado);
        });
    },
  },
};
</script>
