<template>
  <div>
    <div class="row">
      <ed-input-auto-complete
        :class="
          formData.intId == 0
            ? 'col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9'
            : 'col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'
        "
        v-model="formData.intFuncaoId"
        v-if="formData.intId == 0"
        label="Selecionar uma função existente"
        name="intFuncaoId"
        route="Cadastro/Funcao"
        :disabled="formData.boolInserirNovoItem || formData.intId > 0"
        :filters="{
          strOrderBy: 'strNome',
        }"
        @selected="setItem"
      />

      <ed-input-switch
        v-if="formData.intId == 0"
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
        v-model="formData.boolInserirNovoItem"
        name="boolInserirNovoItem"
        label="Inserir função manualmente"
      />

      <ed-input-select
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        v-model="formData.arrayRevisaoSetorId"
        name="arrayRevisaoSetorId"
        label="Em qual Setor a função será incluído ou atualizado?"
        rules="required"
        route="Documento/RevisaoSetor"
        :items="formDataRevisao.setores"
        :disabled="!formData.intFuncaoId && !formData.boolInserirNovoItem"
        multiple
      />

      <ed-input-text
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
        v-model="formData.strCbo"
        name="strCbo"
        label="CBO"
        rules="required"
        :disabled="!formData.intFuncaoId && !formData.boolInserirNovoItem"
      />

      <ed-input-text
        class="col-xs-12 col-sm-12 col-md-12 9-lg-9 col-xl-9"
        v-model="formData.strNome"
        name="strNome"
        label="Nome"
        required
        rules="required"
        :disabled="!formData.intFuncaoId && !formData.boolInserirNovoItem"
      />

      <ed-input-text
        class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
        v-model="formData.intTotalMasculino"
        name="intTotalMasculino"
        label="Total trabalhadores masculino"
        type="number"
        :disabled="!formData.intFuncaoId && !formData.boolInserirNovoItem"
      />

      <ed-input-text
        class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
        v-model="formData.intTotalFeminino"
        name="intTotalFeminino"
        label="Total trabalhadores femenino"
        type="number"
        :disabled="!formData.intFuncaoId && !formData.boolInserirNovoItem"
      />

      <ed-input-text-area
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        v-model="formData.strDescricao"
        name="strDescricao"
        label="Descrição das Atividades da Função"
        rules="required"
        :disabled="!formData.intFuncaoId && !formData.boolInserirNovoItem"
      />
    </div>
  </div>
</template>

<script>
import {
  EdForm,
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputSwitch,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
    formDataRevisao: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdForm,
    EdInputText,
    EdInputTextArea,
    EdInputSelect,
    EdInputSwitch,
    EdInputAutoComplete,
  },
  inject: ["salvar"],
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      objSetor: null,
    };
  },
  computed: {},
  watch: {
    formData: {
      handler: function (newVal) {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    initialize() {
      // if(!this.formData.arrayRevisaoSetorId){
      //   this.formData.arrayRevisaoSetorId = []
      // }
      // if(this.formData.intRevisaoSetorId){
      //   this.formData.arrayRevisaoSetorId.push(this.formData.intRevisaoSetorId)
      // }
      // this.$forceUpdate()
    },
    setItem(objValor) {
      this.formData.strNome = objValor.strNome;
      this.formData.strCbo = objValor.strCbo;
      this.formData.strDescricao = objValor.strDescricao;
    },

    getUltimoSetor() {
      let objUltimoSetor = null;

      if (this.formDataRevisao.setores.length) {
        objUltimoSetor = this.formDataRevisao.setores[
          this.formDataRevisao.setores.length - 1
        ];
      }

      return objUltimoSetor;
    },
  },
};
</script>
