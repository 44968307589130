<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-12 col-xl-12 text-left">
        <span class="form-title">Informações do Setor</span>
      </div>

      <ed-input-auto-complete
        :class="
          formData.intId == 0
            ? 'col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7'
            : 'col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'
        "
        v-model="formData.intCadastroGeralSetorId"
        label="Selecionar um setor existente"
        name="setor"
        route="Cadastro/CadastroGeralSetor"
        :filters="{
          intCadastroGeralEmpresaId: this.formDataRevisao.intCadastroGeralEmpresaId,
        }"
        :initializeItems="formData.intId == 0"
        :itemList="formData.setor ? [formData.setor] : []"
        :disabled="formData.intId ? true : false"
        clearable
        @selected="setItem"
      />

      <ed-input-switch
        v-if="formData.intId == 0"
        class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"
        v-model="formData.boolInserirNovoItem"
        name="boolInserirNovoItem"
        label="Inserir setor manualmente"
      />

      <ed-input-switch
        v-if="formData.intId == 0"
        class="col-xs-12 col-sm-12 col-md-2=3 col-lg-2=3 col-xl-3"
        v-model="formData.boolCopiarSetor"
        name="boolInserirNovoItem"
        label="Copiar dados de outro setor"
        info="Marque essa opção caso optar em copiar os dados de riscos, exames e funções de um determinado setor já existente. Marcando essa opção o sistema vai copiar todos esses dados."
      />

      <ed-input-text
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
        v-model="formData.intOrdem"
        name="intOrdem"
        label="GHE"
        rules="required"
        type="number"
        :readonly="!formData.intCadastroGeralSetorId && !formData.boolInserirNovoItem"
      />

      <ed-input-text
        class="col-xs-12 col-sm-12 col-md-12 9-lg-9 col-xl-9"
        v-model="formData.strNome"
        name="strNome"
        label="Nome"
        required
        rules="required"
        :readonly="!formData.intCadastroGeralSetorId && !formData.boolInserirNovoItem"
      />

      <ed-input-text-area
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        v-model="formData.strDescricaoAtividades"
        name="strDescricaoAtividades"
        label="Descrição do Setor"
        placeholder="Exemplo: Sala de 70m² com piso cerâmico, paredes em alvenaria, ventilação e iluminação artificiais"
        :readonly="!formData.intCadastroGeralSetorId && !formData.boolInserirNovoItem"
      />

      <div
        class="col-xs-12 col-sm-12 col-md-2 col-lg-12 col-xl-12 text-left"
        v-if="formData.boolCopiarSetor"
      >
        <span class="form-title"
          >Selecione os campos abaixo para realizar a cóṕia dos dados</span
        >
      </div>

      <ed-input-auto-complete
        v-if="formData.boolCopiarSetor"
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
        v-model="formData.intCadastroGeralCopiaId"
        label="Empresa"
        name="intCadastroGeralCopiaId"
        route="Cadastro/CadastroGeral"
        rules="required"
        @selected="setItem"
        :filters="{
          intTipoCadastroGeralId: 58,
        }"
      />

      <ed-input-auto-complete
        v-if="formData.boolCopiarSetor"
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
        v-model="formData.intCadastroGeralUnidadeCopiaId"
        label="Unidade de Trabalho"
        name="setor"
        route="Cadastro/CadastroGeralUnidade"
        rules="required"
        :filters="{
          intCadastroGeralEmpresaId: this.formData.intCadastroGeralCopiaId,
        }"
        :disabled="!formData.intCadastroGeralCopiaId"
      />
      <ed-input-auto-complete
        v-if="formData.boolCopiarSetor"
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
        v-model="formData.intRevisaoCopiaId"
        label="Revisão do PCMSO"
        name="setor"
        route="Documento/Revisao"
        rules="required"
        :disabled="!formData.intCadastroGeralUnidadeCopiaId"
        :filters="{
          boolBuscarRevisaoPorUnidade: true,
          intCadastroGeralUnidadeId: this.formData.intCadastroGeralUnidadeCopiaId,
          intTipoRevisaoId: this.bind.objTipoRevisao.intId,
        }"
      />

      <ed-input-auto-complete
        v-if="formData.boolCopiarSetor"
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
        v-model="formData.intRevisaoSetorCopiaId"
        label="Setor do PCMSO"
        name="setor"
        route="Documento/RevisaoSetor"
        rules="required"
        :disabled="
          !formData.intCadastroGeralUnidadeCopiaId || !formData.intRevisaoCopiaId
        "
        :filters="{
          intRevisaoId: formData.intRevisaoCopiaId,
        }"
      />
    </div>
  </div>
</template>

<script>
import {
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputSwitch,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
    formDataRevisao: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdInputText,
    EdInputTextArea,
    EdInputSelect,
    EdInputSwitch,
    EdInputAutoComplete,
  },
  inject: ["bind"],
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      objSetor: null,
    };
  },
  computed: {},
  watch: {
    formData: {
      handler: function (newVal) {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    initialize() {
      if (this.formData.intId == 0) {
        let objUltimoSetor = this.getUltimoSetor();
        this.formData.intOrdem = objUltimoSetor ? objUltimoSetor.intOrdem + 1 : 1;
      }
    },
    setItem(objValor) {
      this.formData.strNome = objValor.strNome;
      this.formData.strDescricaoAtividades = objValor.strDescricaoAtividades;
    },

    getUltimoSetor() {
      let objUltimoSetor = null;

      if (this.formDataRevisao.setores.length) {
        objUltimoSetor = this.formDataRevisao.setores[
          this.formDataRevisao.setores.length - 1
        ];
      }

      return objUltimoSetor;
    },
  },
};
</script>
