var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ed-simple-table',{attrs:{"config":{ registerName: 'Inserir nova função' },"disabledRegisterRows":"","disabledSearchRows":""}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Risco")]),(_vm.bind && _vm.bind.objTipoDocumento && _vm.bind.objTipoDocumento.intId != 279)?_c('th',{staticClass:"text-left"},[_vm._v(" Meio de Prograpação ")]):_vm._e(),(_vm.bind && _vm.bind.objTipoDocumento && _vm.bind.objTipoDocumento && _vm.bind.objTipoDocumento.intId != 279)?_c('th',{staticClass:"text-left",attrs:{"width":"170"}},[_vm._v(" Class. Ocupacional ")]):_vm._e(),(_vm.bind && _vm.bind.objTipoDocumento && _vm.bind.objTipoDocumento.intId != 279)?_c('th',{staticClass:"text-left",attrs:{"width":"170"}},[_vm._v(" Class. Residual ")]):_vm._e(),_c('th',{staticClass:"text-left",attrs:{"width":"105"}})])]),_c('tbody',[_vm._l((_vm.formData.riscos),function(objRevisaoRisco,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-left"},[_c('ed-collapse',{attrs:{"textLimit":100,"textValue":objRevisaoRisco.risco.strNome}}),_c('span',{staticClass:"ed-table-info"},[_c('label',[_c('b',[_vm._v("CATEGORIA:")]),_vm._v(" "+_vm._s(objRevisaoRisco.risco.categoria ? objRevisaoRisco.risco.categoria.strNome : "---")+" ")])])],1),(_vm.bind.objTipoDocumento && _vm.bind.objTipoDocumento.intId != 279)?_c('td',_vm._l((objRevisaoRisco.risco
              .meio_propagacao),function(objMeioPropagacao,indexMeioPropagacao){return _c('span',{key:indexMeioPropagacao,staticStyle:{"display":"block","width":"100%"}},[_vm._v(" "+_vm._s(objMeioPropagacao.meio_propagacao.strNome)+" ")])}),0):_vm._e(),(_vm.bind.objTipoDocumento && _vm.bind.objTipoDocumento.intId != 279)?_c('td',[(objRevisaoRisco.matriz_ocupacional)?_c('span',{style:('background:' + objRevisaoRisco.matriz_ocupacional.strValor.strCor)},[_vm._v(" "+_vm._s(objRevisaoRisco.matriz_ocupacional.strNome)+" "),(
                objRevisaoRisco.probabilidade_ocupacional &&
                objRevisaoRisco.severidade_ocupacional
              )?_c('span',{staticStyle:{"width":"100%","display":"block","font-size":"8pt"}},[_vm._v(" P"+_vm._s(objRevisaoRisco.probabilidade_ocupacional.strCodigo)+" x S"+_vm._s(objRevisaoRisco.severidade_ocupacional.strCodigo)+" ")]):_vm._e()]):_vm._e()]):_vm._e(),(_vm.bind.objTipoDocumento && _vm.bind.objTipoDocumento.intId != 279)?_c('td',[(objRevisaoRisco.matriz_residual)?_c('span',{style:('background:' + objRevisaoRisco.matriz_residual.strValor.strCor)},[_vm._v(" "+_vm._s(objRevisaoRisco.matriz_residual.strNome)+" "),(
                objRevisaoRisco.probabilidade_residual &&
                objRevisaoRisco.severidade_residual
              )?_c('span',{staticStyle:{"width":"100%","display":"block","font-size":"8pt"}},[_vm._v(" P"+_vm._s(objRevisaoRisco.probabilidade_residual.strCodigo)+" x S"+_vm._s(objRevisaoRisco.severidade_residual.strCodigo)+" ")]):_vm._e()]):_vm._e()]):_vm._e(),_c('td',{staticClass:"text-right"},[(!_vm.boolDisableControls)?_c('ed-button',{attrs:{"iconLeft":_vm.$utilidade.getIcone('editar') + ' fa-3x',"color":"primary","title":"Editar Setor","icon":"","onlyIcon":""},on:{"click":function($event){!_vm.boolDisableControls ? _vm.onEditarRisco(objRevisaoRisco) : null}}}):_vm._e(),(!_vm.boolDisableControls)?_c('ed-button',{attrs:{"iconLeft":_vm.$utilidade.getIcone('deletar') + ' fa-3x',"color":"error","title":"Deletar Setor","icon":"","onlyIcon":""},on:{"click":function($event){!_vm.boolDisableControls ? _vm.onDelete(objRevisaoRisco) : null}}}):_vm._e()],1)])}),(!_vm.formData.riscos.length)?_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_vm._v("Nenhum risco encontrado")])]):_vm._e()],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }