<template>
  <div>
    <ed-simple-table
      :config="{ registerName: 'Inserir nova função' }"
      disabledRegisterRows
      disabledSearchRows
    >
      <thead>
        <tr>
          <th class="text-left" v-if="!boolDisableControls">CBO</th>
          <th class="text-left">Nome</th>
          <th class="text-left" v-if="!boolDisableControls">Atividades</th>
          <th class="text-left" v-if="!boolDisableControls"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(objRevisaoFuncao, index) in formData.funcoes" :key="index">
          <td class="text-left" v-if="!boolDisableControls">
            {{ objRevisaoFuncao.strCbo }}
          </td>
          <td class="text-left">{{ objRevisaoFuncao.strNome }}</td>
          <td v-if="!boolDisableControls">
            <ed-collapse :textLimit="20" :textValue="objRevisaoFuncao.strDescricao" />
          </td>
          <td class="text-right" v-if="!boolDisableControls">
            <ed-button
              v-if="!boolDisableControls && onEditarFuncao"
              :iconLeft="$utilidade.getIcone('editar') + ' fa-3x'"
              color="primary"
              title="Editar Setor"
              icon
              onlyIcon
              @click="onEditarFuncao(objRevisaoFuncao)"
            />

            <ed-button
              v-if="!boolDisableControls"
              :iconLeft="$utilidade.getIcone('deletar') + ' fa-3x'"
              color="error"
              title="Deletar Setor"
              icon
              onlyIcon
              @click="!boolDisableControls ? onDelete(objRevisaoFuncao) : null"
            />
          </td>
        </tr>
        <tr v-if="!formData.funcoes.length">
          <td colspan="3">Nenhum registro encontrado</td>
        </tr>
      </tbody>
    </ed-simple-table>
  </div>
</template>

<script>
import { EdSimpleTable, EdCollapse, EdButton } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
    boolDisableControls: {
      type: [Boolean],
      required: false,
      default: false,
    },
  },
  components: {
    EdSimpleTable,
    EdCollapse,
    EdButton,
  },
  inject: ["onEditarFuncao", "getDetalheRevisao"],
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      boolExibirFormulario: false,
    };
  },
  computed: {},
  watch: {
    formData: {
      handler: function (newVal) {},
      deep: true,
    },
  },
  methods: {
    initialize() {},

    onDelete(objSetor) {
      this.$root.$dialog
        .confirm(
          "Tem certeza que deseja excluir a função " + objSetor.strNome + " desse setor?"
        )
        .then((boolExcluir) => {
          this.$root.$dialog.loading(true);

          this.$root.$request
            .delete("Documento/RevisaoSetorFuncao", {
              arrayId: [objSetor.intId],
            })
            .then((objResponse) => {
              this.$root.$dialog.loading(false);

              if (objResponse.status == 200) {
                this.getDetalheRevisao();
              }
            });
        });
    },
  },
};
</script>
